import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import ContentOverlay from '../../components/ContentOverlay/ContentOverlay';
import TeamsGoalsWizard from '../../components/Goals/TeamsGoalsWizard';
import GoalsList from '../../components/Goals/GoalsList';
import GoalsFilters from '../../components/Goals/GoalsFilters';
import GoalDetails from '../../components/Goals/GoalDetails';
import ResultsSummary from '../../components/AdminTable/ResultsSummary';
import Container from '../../components/Container/Container';
import FormatUtil from '../../utils/formatUtil';
import ActionUtil from '../../utils/actionsUtil';

function DeleteGoalConfirmText({ goalData, columns, goalStatus }) {
  const dueTypeIdx = FormatUtil.getColIndex(columns, 'due_type');
  const isRecurring = goalData[dueTypeIdx] && goalData[dueTypeIdx].value === 'recurring';
  const isUpcoming = goalStatus === 'upcoming';
  let mainText = 'Are you sure you want to delete this goal? This action cannot be undone';
  if (isUpcoming) {
    mainText += '.';
  } else {
    mainText += ' and all goal progress will be lost.';
  }
  let secondaryText = null;
  if (isRecurring) {
    if (isUpcoming) {
      secondaryText = 'The current active recurring goal will no longer recur.';
    } else {
      secondaryText = 'This will delete all upcoming occurrences of this goal.';
    }
  }
  return (
    <>
      <p className={secondaryText ? 'mb-2' : 'mb-0'}>{mainText}</p>
      {secondaryText && <p className="mb-0">{secondaryText}</p>}
    </>
  );
}

const TeamGoals = inject(
  'commonStore',
  'userStore',
  'authStore',
  'goalsStore',
  'enterpriseStore',
  'teamHierarchyStore'
)(
  observer(
    class TeamGoals extends Component {
      state = {
        sliderOpen: false,
        sliderView: null,
        goalId: null,
        teamId: null,
        goalsListingTeamGroupId: null,
        goalListGroupScopeQuery: '',
        goalsAdminTeamGroupId: null,
      };

      componentDidMount() {
        const isDemo = this.props.match.params.orgId === 'demo';
        if (isDemo) {
          this.props.userStore.enterDemo();
          this.props.goalsStore.enterDemo();
        } else {
          this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
        }
        this.props.authStore.fireAttributionEvent();
        this.setNewPageTitle(this.props.match.params.section, isDemo);
        // scroll to top
        ActionUtil.scrollToTop();
      }

      componentDidUpdate(prevProps) {
        if (this.props.match.params.section !== prevProps.match.params.section) {
          const { setQueryParams, setGoalOwnersList } = this.props.goalsStore;
          setQueryParams('teamGoals', 'ownerIds', []);
          this.getTeamGoals().then((goals) => {
            setGoalOwnersList('teamGoals', goals || [], true);
          });
          this.setNewPageTitle(this.props.match.params.section);
        }
      }

      componentWillUnmount() {
        this.props.goalsStore.setDefaultGoalOwners();
        this.props.goalsStore.setDefaultGoalParticipants();
        this.props.goalsStore.setDefaultPaths();
        this.props.goalsStore.setDefaultLearningActivities();
        this.props.enterpriseStore.setDefaultGroupList();
        this.props.goalsStore.setDefaultCertifications();
        this.props.goalsStore.setDefaultTeamGoals();
        const isDemo = this.props.match.params.orgId === 'demo';
        if (isDemo) {
          this.props.goalsStore.exitDemo();
          this.props.userStore.exitDemo();
        }
      }

      init = () => {
        const { team } = this.props.userStore;
        const { getPaths, getCertifications, getGoalParticipantsData, getGoalOwnersData, setGoalOwnersList } = this.props.goalsStore;

        const newState = {
          ...this.state,
          teamId: team.id,
          goalsListingTeamGroupId: team.permissions.canViewReports === 'all' ? team.id : team.goalsTeamGroupId,
          goalsAdminTeamGroupId: team.goalsTeamGroupId,
        };
        const queryParams = queryString.parse(window.location.search);
        // If we have a query param specifying a goal, open that goal details
        if (queryParams.goal) {
          newState.sliderOpen = true;
          newState.sliderView = 'details';
          newState.goalId = queryParams.goal;
        }
        // If we have a query param specifying an action, act on it
        if (queryParams.action && queryParams.action === 'add') {
          newState.sliderOpen = true;
          newState.sliderView = 'add';
        }
        // Preload all of the goal add/edit form option data
        getPaths(newState.teamId, team.package_types);
        getCertifications();
        this.props.teamHierarchyStore.getTeamHierarchy(team).then((teamHiearchy) => {
          // Query to be appended to goal list route - Contains all group IDs for groups the user can either manage or view reports for
          // (no query if team admin or team reporting admin)
          newState.goalListGroupScopeQuery = this.getGroupScopeQuery(teamHiearchy, true);
          // Query to be appended to goal list route - Contains all group IDs for groups the user can manage only (no query if team admin)
          const goalOptionsGroupScopeQuery = this.getGroupScopeQuery(teamHiearchy, false);
          const formattedGoalOptionsGroupScopeQuery = goalOptionsGroupScopeQuery && goalOptionsGroupScopeQuery.length ? `?${goalOptionsGroupScopeQuery}` : '';
          getGoalParticipantsData(newState.teamId, formattedGoalOptionsGroupScopeQuery);
          this.setState(newState, () => {
            this.getTeamGoals().then((goals) => {
              setGoalOwnersList('teamGoals', goals || [], true);
            });
          });
          // Get admins for all groups user has permission to manage. Add those to the owners list
          getGoalOwnersData(newState.teamId, team.permissions.canManageTeam, this.props.teamHierarchyStore.trees[team.id].data);
          this.props.teamHierarchyStore.setHierarchySelectOptions(team.id, this.filterGroupsByPermissions, null, null, true);
        });
      };

      setNewPageTitle = (section, isDemo) => {
        // this.props.match.params.section is completed or undefined... when undefined use 'In Progress'
        let pageSection = 'Active';
        if (section === 'completed') {
          pageSection = 'History of';
        } else if (section === 'upcoming') {
          pageSection = 'Upcoming';
        }
        return this.props.commonStore.setPageTitle(`${pageSection} Team Goals${isDemo ? ' Demo' : ''} | Cybrary`);
      };

      getTeamGoals = () => {
        // If section is 'Completed', get completed goals
        let viewParam = `?isComplete=${this.props.match.params.section === 'completed' ? 1 : 0}`;
        viewParam += `&isScheduled=${this.props.match.params.section === 'upcoming' ? 1 : 0}`;

        const groupScopeParams = this.state.goalListGroupScopeQuery && this.state.goalListGroupScopeQuery.length ? `&${this.state.goalListGroupScopeQuery}` : '';
        return this.props.goalsStore.getTeamGoals(this.state.goalsListingTeamGroupId, `${viewParam}${groupScopeParams}`);
      };

      // Build a query string to filter routes by groups the user has permissions to manage or see reports for
      getGroupScopeQuery = (teamHierarchy, isGoalList) => {
        const { team } = this.props.userStore;

        let query = '';
        // If user can manage team, no need to scope by groups
        if (team.permissions && team.permissions.canManageTeam) {
          return query;
        }
        // If this is the list view and the user can view all reports, no need to scope by groups
        if (isGoalList && team.permissions && team.permissions.canViewReports === 'all') {
          return query;
        }
        // Get all of the groups the user can manage. If isGoalList, include groups the user can view reports for
        if (teamHierarchy && teamHierarchy.length) {
          const groupsArray = this.getGroups(teamHierarchy, !!isGoalList);
          query = groupsArray.length ? `groupIds[]=${groupsArray.join('&groupIds[]=')}` : '';
        }

        return query;
      };

      // Get an array of groups that this user can manage, or groups the user can see reports for if includeReporting=true
      getGroups = (hierarchy, includeReporting) => {
        const groups = [];
        hierarchy.forEach((group) => {
          const children = this.getGroups(group.children, includeReporting);
          if (group.permissions && (group.permissions.manage === 'edit' || (includeReporting && group.permissions.reports))) {
            groups.push(group.id);
          }
          groups.push(...children);
        });
        return groups;
      };

      /* Finds a group that the user is an admin of to use for routes if not team admin */
      getTeamGroupId = (team) => {
        const adminGroup = team.adminGroupsList[0];
        const reportingAdminGroup = team.reportAdminGroupsList[0];
        return adminGroup || reportingAdminGroup;
      };

      /**
       * Filter passed to hierarchy creation to filter out any groups that the user doesn't have permission to view
       */
      filterGroupsByPermissions = (group) => group.permissions.manage === 'edit';

      toggleSlider = (slider, goalId, openState) => {
        const newState = {
          ...this.state,
          sliderView: slider || null,
          sliderOpen: openState || !this.state.sliderOpen,
          goalId: goalId || null,
        };
        // Check if there is a goal query param that auto opens goal on page load, if so remove it
        const queryParams = queryString.parse(window.location.search);
        if (queryParams.goal) {
          delete queryParams.goal;
          this.props.navigate({
            search: queryString.stringify(queryParams),
          });
        }
        // If there are queryParams and an 'add' action, clear all params
        if (queryParams.action && queryParams.action === 'add') {
          this.props.navigate({
            search: '',
          });
        }
        this.setState(newState);
      };

      cancelConfirm = (callback, backToDetails) => {
        const toggleData = backToDetails ? ['details', this.state.goalId, true] : [];
        if (!this.props.goalsStore.addEditGoalPrestine) {
          this.props.commonStore.triggerConfirm({
            content: 'Are you sure you want to cancel? Your goal will not be saved',
            cancel: () => this.props.commonStore.resetConfirmState(),
            confirmBtn: 'Continue',
            continue: () => {
              this.props.commonStore.resetConfirmState();
              this.toggleSlider(...toggleData);
              this.props.goalsStore.resetAddEditGoalPrestine();
              if (callback) {
                callback();
              }
            },
          });
        } else {
          this.toggleSlider(...toggleData);
          if (callback) {
            callback();
          }
        }
      };

      viewGoal = (goalData, columns) => {
        const idIdx = FormatUtil.getColIndex(columns, 'id');
        const goalId = goalData[idIdx] && goalData[idIdx].value ? goalData[idIdx].value : null;
        if (goalId) {
          this.toggleSlider('details', goalId);
        }
      };

      toggleEditGoal = (goalData, columns, goalId) => {
        let goal = goalId;
        if (!goal) {
          const idIdx = FormatUtil.getColIndex(columns, 'id');
          goal = goalData[idIdx] && goalData[idIdx].value ? goalData[idIdx].value : null;
        }
        if (goal) {
          this.toggleSlider('edit', goal, true);
        }
      };

      deleteGoalConfirm = (goalData, columns) => {
        const idIdx = FormatUtil.getColIndex(columns, 'id');
        const goalId = goalData[idIdx] && goalData[idIdx].value ? goalData[idIdx].value : null;
        if (goalId) {
          this.props.commonStore.triggerConfirm({
            content: <DeleteGoalConfirmText goalData={goalData} columns={columns} goalStatus={this.props.match.params.section} />,
            cancel: () => this.props.commonStore.resetConfirmState(),
            confirmBtn: 'Continue',
            continue: () => {
              this.props.commonStore.resetConfirmState();
              this.deleteGoal(goalId);
            },
          });
        }
      };

      deleteGoal = (goalId) => {
        this.props.goalsStore
          .deleteGoal(goalId, this.state.goalsAdminTeamGroupId)
          .then(() => {
            this.props.commonStore.triggerToast('success', { content: 'Goal deleted.' });
            this.getTeamGoals();
          })
          .catch(() => {
            this.props.commonStore.triggerToast('error', { content: 'Something went wrong. Unable to delete this goal at this time.' });
          });
      };

      handleFilterChange = (key, val) => {
        this.props.goalsStore.setQueryParams('teamGoals', key, val);
        this.getTeamGoals();
      };

      render() {
        const { team } = this.props.userStore;
        // Wait for team to be set
        if (!team) {
          return null;
        }
        const { sliderOpen, sliderView, goalId, goalListGroupScopeQuery } = this.state;
        const { teamGoals, setQueryParams } = this.props.goalsStore;
        const wizardTitle = `${goalId ? 'Edit' : 'Create'} Goal`;
        const isDemo = team.id === 'demo';

        return (
          <Container size="lg" className="team-goals">
            <div className="goals-management">
              <div className="items-center border-b-xs border-gray-400 sm:flex">
                <div className="flex-1">
                  <div className="inline-block">
                    <Title title="Goals" />
                  </div>
                </div>
                {team.permissions && team.permissions.canManageAssignments ? (
                  <div className="mb-4 sm:flex-1 sm:mb-0 sm:text-right">
                    <button
                      className={`py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm ${
                        isDemo ? 'cursor-not-allowed' : ''
                      }`}
                      onClick={() => this.toggleSlider('add')}
                      disabled={isDemo}
                    >
                      + Add new
                    </button>
                  </div>
                ) : null}
              </div>
              <GoalsFilters
                className="pt-8"
                onFilterChange={this.handleFilterChange}
                options={teamGoals.ownersList.slice()}
                values={teamGoals.queryParams.ownerIds.slice()}
                disabled={isDemo}
              />
              <ResultsSummary srOnly numOfResults={teamGoals && teamGoals.goalsCount} />
              <GoalsList
                viewGoal={this.viewGoal}
                editGoal={isDemo ? () => {} : this.toggleEditGoal}
                deleteGoal={isDemo ? () => {} : this.deleteGoalConfirm}
                isCompleted={this.props.match.params.section === 'completed'}
                setQueryParams={setQueryParams}
                teamGoals={teamGoals}
                getTeamGoals={isDemo ? () => {} : this.getTeamGoals}
                team={team}
              />
              <ContentOverlay
                omitPadding
                open={sliderOpen}
                dismiss={this.cancelConfirm}
                width={sliderView === 'add' ? 'w-[70vw]' : 'w-[40vw]'}
                maxWidth={sliderView === 'details' ? 'max-w-xl' : 'max-w-5xl'}
                ariaLabelledBy="goal-title"
              >
                {sliderView === 'add' ? (
                  <div className="py-4">
                    <div className="px-8 mb-9">
                      <Title title={wizardTitle} titleId="goal-title" omitPadding wrapperClasses="mb-4" />
                      <p className="hidden text-sm text-gray-600 md:block">
                        Goals help you track your team&apos;s learning and the on-the-job outcomes you care about.
                        <br />
                        Follow this step-by-step guide to create a goal for your team.
                      </p>
                    </div>
                    <TeamsGoalsWizard
                      team={team}
                      teamGroupId={this.state.goalsAdminTeamGroupId}
                      goalId={goalId}
                      onSubmit={() => {
                        this.toggleSlider();
                        this.getTeamGoals();
                      }}
                      filterGroupsByPermissions={this.filterGroupsByPermissions}
                      queryParams={queryString.parse(window.location.search)}
                    />
                  </div>
                ) : null}
                {sliderView === 'details' || sliderView === 'edit' ? (
                  <GoalDetails
                    goalId={goalId}
                    groupScopeQuery={goalListGroupScopeQuery}
                    progressTeamGroupId={this.state.goalsListingTeamGroupId}
                    editTeamGroupId={this.state.goalsAdminTeamGroupId}
                    teamId={this.props.match.params.orgId}
                    toggleEditGoal={this.toggleEditGoal}
                    onEdit={() => {
                      this.toggleSlider('details', goalId, true);
                      this.getTeamGoals();
                    }}
                    editView={sliderView === 'edit'}
                    editCancel={(callback) => this.cancelConfirm(callback, true)}
                    filterGroupsByPermissions={this.filterGroupsByPermissions}
                    teamAdminView
                  />
                ) : null}
              </ContentOverlay>
            </div>
          </Container>
        );
      }
    }
  )
);

export default withRouter(TeamGoals);
