import moment from 'moment';
import { MANAGER_PLUS_ROLES } from '../constants';

// For Team Discovery - For now just grabbing and checking the first team
// Note: Have to use this method rather than userStore.team as that could be null in this case due to user not actually being on team (no role)
// and therefore is_enterprise = false and team not set in store
export function getFirstUserTeam(teams) {
  if (!teams || !Object.keys(teams).length) {
    return null;
  }
  const teamId = Object.keys(teams)[0];
  return teams[teamId];
}
export function isEnterpriseAdmin(userStore) {
  if (!userStore?.teamsWithAdminAccess) {
    return false;
  }
  const keys = Object.keys(userStore?.teamsWithAdminAccess);
  return !!keys.length;
}
// Return true if we are an enterprise user
export function isEnterpriseUser(userStore) {
  return userStore?.isEnterprise;
}

// Return true if we are an enterprise user
export function isNonEnterpriseUser(userStore) {
  return !userStore?.isEnterprise;
}

// Return true if we are membershipFor = team and isEnterprise = false
export function isEnterpriseLead(userStore) {
  return !userStore?.isEnterprise && userStore?.user?.onboarding_data?.membershipFor === 'team';
}

export function showTeamLogos(userStore) {
  const onTeamAdminPages = /^\/enterprise\/.*/g.test(window.location.pathname);
  return userStore && !onTeamAdminPages && userStore?.isEnterprise;
}

// Perform the replacement for team invites
export function teamInviteHrefReplace(href, userStore) {
  const team = getFirstUserTeam(userStore.userTeams);
  if (team && team.invite_url) {
    return team.invite_url;
  }
  return href;
}

// Return true if a free user (free and not on a team)
export function isFreeUser(userStore) {
  return userStore?.isFree;
}

// Return true if user is free OR cip
export function isFreeOrCipUser(userStore) {
  return userStore?.isFree || userStore?.isCip;
}

// Return true if a inactive team user (free and on an inactive team)
export function isUserOnActiveTeam(userStore) {
  return userStore?.isUserOnActiveTeam;
}

// Return true if we are a CIP
export function isCipUser(userStore) {
  return userStore?.isCip;
}

export function isTeamMember(userStore) {
  return !userStore?.teamData?.role || userStore?.teamData?.role === 'team-member';
}

// Replace :orgId with the currently selected team
export function teamHrefReplace(href, userStore) {
  if (userStore) {
    const { adminTeam: team } = userStore;
    if (team) {
      const { id } = team;
      return href.replace(':orgId', id);
    }
  }
  return href;
}

// Replace :orgId with the currently selected team & if user can't manage team, change link to groups link
export function teamPeopleHrefReplace(href, userStore) {
  if (userStore) {
    const { adminTeam: team } = userStore;
    if (team) {
      const { id } = team;
      let newHref = href.replace(':orgId', id);
      if (team.permissions && !team.permissions.canManageTeam) {
        newHref = newHref.replace('members', 'groups');
      }
      return newHref;
    }
  }
  return href;
}

export function canManageAdmins(userStore) {
  if (userStore && userStore.team && userStore.team.permissions) {
    return userStore.team.permissions.canManageAdmins;
  }
  return false;
}

// Return true if a team admin
export function teamAdmin(userStore) {
  if (!userStore) {
    return false;
  }
  return userStore.isEnterpriseAdmin;
}

export function canManageTeamOrGroups(userStore) {
  if (userStore) {
    const { adminTeam: team } = userStore;
    if (team && team.permissions) {
      return team.permissions.canManageTeam || team.permissions.canManageGroup;
    }
  }
  return false;
}

export function canManageTeam(userStore) {
  if (userStore) {
    const { adminTeam: team } = userStore;
    if (team && team.permissions) {
      return team.permissions.canManageTeam;
    }
  }
  return false;
}

export function canManageGroups(userStore) {
  if (userStore) {
    const { adminTeam: team } = userStore;
    if (team && team.permissions) {
      return team.permissions.canManageGroup || team.permissions.canViewReports;
    }
  }
  return false;
}

export function canManageAssignments(userStore) {
  if (userStore) {
    const { adminTeam: team } = userStore;
    const teamCreationDate = moment(team?.created_at);
    const cutoffDate = moment('2021-04-01 00:00:00').utc();
    if (team && team.permissions && teamCreationDate.isBefore(cutoffDate, 'day')) {
      return team.permissions.canManageAssignments;
    }
  }
  return false;
}

export function canViewAnalytics(userStore) {
  if (userStore) {
    const { adminTeam: team } = userStore;
    if (team && team.permissions) {
      return team.permissions.canManageTeam || team.permissions.canManageGroup || team.permissions.canViewReports;
    }
  }
  return false;
}

export function canViewPaths(userStore) {
  if (userStore) {
    const { adminTeam: team } = userStore;
    if (team && team.permissions) {
      return team.permissions.canManageCurricula || team.permissions.canViewReports;
    }
  }
  return false;
}

export function canViewPathsBeta(userStore, isBetaEnabled) {
  if (!isBetaEnabled) {
    return false;
  }

  if (userStore) {
    const { adminTeam: team } = userStore;
    if (team && team.permissions) {
      return team.permissions.canManageCurricula || team.permissions.canViewReports;
    }
  }
  return false;
}

export function canViewTeamGoals(userStore) {
  if (userStore) {
    const { adminTeam: team } = userStore;
    if (team && team.permissions) {
      return team.permissions.canViewReports;
    }
  }
  return false;
}

export function canJoinTeam(userStore) {
  const team = getFirstUserTeam(userStore.userTeams);
  return team && !team.role && team.invited_by_domain && team.invite_url;
}

export function canRequestLicense(userStore) {
  return userStore && userStore.isEnterprise && userStore.isFree && userStore.activeTeams.length;
}

// NOTE: Clab Access checks to be removed when Beta is over
export function canAccessClab(userStore) {
  if (userStore) {
    const { adminTeams = {}, userTeams = {}, user } = userStore;
    const adminTeamsIds = adminTeams ? Object.keys(adminTeams) : [];
    const adminTeamsWithClabBeta = adminTeamsIds.filter((id) => adminTeams[id].clab_access);
    const teams = userTeams ? Object.keys(userTeams) : [];
    const teamsWithClabBeta = teams.filter((id) => userTeams[id].clab_access);

    // If user has admin access to any teams with clab beta - Can access page
    if (adminTeamsWithClabBeta.length) {
      return true;
    }

    // If user is assigned access to clab beta personally, AND is on any team that has access to clab beta - Can access page
    if (user.clab_access && teamsWithClabBeta.length) {
      return true;
    }
  }
  return false;
}

export function isAdmin(userStore, isPaid = false) {
  const VALID_ROLES = ['org-owner', 'team-admin', 'team-reporting-admin', 'team-group-admin'];

  const isValidAdmin = VALID_ROLES.includes(userStore?.teamData?.role);

  if (isPaid) {
    return Boolean(userStore?.user?.is_paid) && VALID_ROLES.includes(userStore?.teamData?.role);
  }

  return isValidAdmin;
}

/**
 * Redirect to `href` if user org role is an owner, admin, or member, else redirect to `fallbackHref`
 * @param {string} href
 * @param {string} fallbackHref
 * @param {*} userStore
 */
export function redirectOrgOwnerAdminMember(href, fallbackHref, userStore) {
  const VALID_ROLES = ['org-owner', 'team-admin', 'team-reporting-admin', 'team-group-admin', 'team-member'];
  const isValidRole = VALID_ROLES.includes(userStore?.teamData?.role);

  if (isValidRole) {
    return href;
  }
  return fallbackHref;
}

/**
 * Redirect to `href` if user org role is an owner or admin or if user is cip or free and identifies as manager+
 * @param {*} href
 * @param {*} userStore
 */
export function redirectOrgOwnerAdminPlus(href, userStore) {
  const VALID_ROLES = ['org-owner', 'team-admin']; // potentially expand to 'team-reporting-admin' & 'team-group-admin'
  const isValidRole = VALID_ROLES.includes(userStore?.teamData?.role);
  const isCipOrFree = userStore?.user?.is_cip || !userStore?.user?.is_paid;
  const isManagerPlus = MANAGER_PLUS_ROLES.includes(userStore?.user?.onboarding_data?.title);

  if (isValidRole || (isCipOrFree && isManagerPlus)) {
    return href;
  }
  return null;
}
