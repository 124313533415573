import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { twMerge } from 'tailwind-merge';
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker';
import AddLink from '../../../AddLink/AddLink';
import { BASE_BUTTON_STYLES } from '../../../../constants';
import { trackSnowplowEvent } from '../../../../utils/snowplowUtil';

function UserAccessDeniedPrompt({ userId = -1 }) {
  return (
    <div className="flex flex-row items-center mx-2 mt-6">
      <div className="flex flex-1">
        <Vimeo className="w-full" id="video-player-wrapper" autoplay responsive speed video="https://player.vimeo.com/video/985079390?h=47e03d73d3&badge=0&app_id=58479" />
      </div>
      <div className="flex flex-1 justify-center">
        <div className="px-15">
          <p className="text-md">
            <b>Baselining is a premium feature only available to Cybrary for Teams Customers.</b>
          </p>
          <p className="text-sm">
            <b>Enumerate Skill Gaps:</b> Measure your team&apos;s current aptitude in critical cybersecurity job roles and topics using hands-on assessments to develop a clear
            understanding of their current capabilities.
          </p>
          <p className="text-sm">
            <b>Remediate Skill Gaps:</b> Based on their pre-assessment results, each member of your team will receive a customized remediation path to address potential skill gaps.
          </p>
          <p className="text-sm">
            <b>Visualize Growth:</b> Upon completing their remediation path, members of your team will re-take each assessment to demonstrate their progress in each topic area.
          </p>
          <p className="text-sm">
            <b>For more information about Baselining and other Teams-exclusive features, sign up for a demo today!</b>
          </p>
          <div className="mt-8 text-center">
            <AddLink
              className={twMerge(BASE_BUTTON_STYLES, 'bg-cyb-pink-500 hover:bg-pink-600')}
              to="https://www.cybrary.it/demo-request"
              onClick={() => {
                trackSnowplowEvent({ category: 'BaselineTeams', action: 'click', label: 'requestDemoButton' });
                trackSelfDescribingEvent({
                  event: {
                    schema: 'iglu:com.cybrary/BaselineDemoRequest/jsonschema/1-0-0',
                    data: {
                      event: 'BaselineDemoRequest',
                      userId,
                    },
                  },
                });
              }}
              target="_blank"
            >
              Request Demo
            </AddLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAccessDeniedPrompt;
