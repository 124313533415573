import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Container from '../Container/Container';
import OnboardingRouter from './OnboardingRouter';

const OnboardingPage = inject('commonStore')(
  observer(({ commonStore }) => {
    useEffect(() => {
      commonStore.hidePrimaryNav();
      commonStore.setHideFooter(true);
      return () => {
        commonStore.showPrimaryNav();
        commonStore.setHideFooter(false);
      };
    }, []);
    return (
      <Container size="dashboard" className="flex-col w-[100vw] h-[100vh]">
        <OnboardingRouter />
      </Container>
    );
  })
);

export default OnboardingPage;
