import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { DEFAULT_COOKIE_SETTINGS, PUBLIC_PAGES } from '../../constants';
import RecoveryEmailForm from './RecoveryEmailForm';
import Modal from '../Modal/Modal';
import PermalinkUtil from '../../utils/permalinkUtil';

const TeamsSecondEmailModal = inject('userStore')(
  observer(({ userStore }) => {
    const location = useLocation();
    const [open, setOpen] = useState(false);

    const { profileData, user, team } = userStore;
    const dismissedSecondEmailModal = Cookies.get('second_email_dismiss');

    useEffect(() => {
      // Get list of important pages that should be omitted from forcing this modal onto user
      const whiteListedPages = [...PUBLIC_PAGES, '/logout', '/onboarding/terms-of-service', '/onboarding/recovery-email', '/upgrade/teams-checkout', '/upgrade/checkout'];
      const isTeamInvitePage = /^\/team-invite*/.test(location.pathname);
      const isTeamDemo = /^\/enterprise\/demo*/.test(location.pathname);

      const cleanPathName = PermalinkUtil.removeTrailingSlash(location.pathname);
      const needsRecoveryEmail = team && userStore.user.total_accounts < 2;
      /*
        Only open if you have user data, 
        not on a whitelisted page, 
        not on the team-invite page, 
        and when we have profile data (we don't want this to be triggered with the onboarding modal )
      */
      setOpen(user && needsRecoveryEmail && !whiteListedPages.includes(cleanPathName) && !isTeamInvitePage && !!profileData && !isTeamDemo);
    }, [location]);

    const handleDismissModal = () => {
      setOpen(false);
      Cookies.set('second_email_dismiss', 'dismissed', {
        ...DEFAULT_COOKIE_SETTINGS,
        path: '/',
        expires: 365,
      });
    };

    if (dismissedSecondEmailModal === 'dismissed') {
      return null;
    }

    return (
      <Modal closeXClassName="hidden" sizeClasses="md:w-[43.75rem]" open={open}>
        {/* Toggled by completion of teams form and logic in callback function (team user with less then 2 emails) */}
        <div className="p-8 pb-0">
          <h2 className="my-6 text-lg font-black leading-10 text-center md:mt-16 md:mb-6 md:text-3xl 2xl:text-5xl">Recovery Email</h2>
          <p className="mb-0 text-sm">If you lose access to your primary email, you will lose access to your Cybrary account.</p>
          <p className="text-sm">This email will be used for recovery purposes only.</p>
          <p className="mb-12 text-sm">Add another email to make sure you&apos;ll never lose access to Cybrary.</p>
          <RecoveryEmailForm successCallback={() => setOpen(false)} dismissCallback={handleDismissModal} />
        </div>
      </Modal>
    );
  })
);

export default TeamsSecondEmailModal;
