import React, { Component, useState, useRef, useMemo, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import CurriculumBuilder from '../Curriculum/CurriculumBuilder';
import GoalsUtil from '../../utils/goalsUtil';
import FormatUtil from '../../utils/formatUtil';
import SearchInput from '../Search/SearchInput';
import Wizard from '../Wizard/Wizard';
import SelectBoxes from '../SelectBoxes/SelectBoxes';
import WizardTimeframeSelection from './WizardTimeframeSelection';
import WizardStartTimeSelection from './WizardStartTimeSelection';
import WizardParticipantsSelection from './WizardParticipantsSelection';
import WizardStepDescription from './WizardStepDescription';
import WizardOutcomeSelection from './WizardOutcomeSelection';
import WizardOutcomeStepperDetails from './WizardOutcomeStepperDetails';
import WizardOwnersSelection from './WizardOwnersSelection';
import Divider from '../Divider/Divider';
import Modal from '../Modal/Modal';
import If from '../If/If';

function GoalOutcome({ setValue, selectedOutcomeType, selectedOutcomeSettings, isEditView }) {
  const inputRef = useRef();

  const handleSelection = (section, val) => {
    if (section === 'outcome_type') {
      setValue(section, val);
      if (val.includes('_program')) {
        setValue('content_type', val);
      }
      setValue('outcome_settings', {});
      const scrollSections = ['learning_hours', 'ceus'];
      if (scrollSections.indexOf(val) > -1) {
        setTimeout(() => {
          inputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    } else {
      // handle outcome_settings
      const settings = {};
      // CEUs and learning hours outcome types need settings set. Learning hours needs to save in seconds
      if (selectedOutcomeType === 'ceus' && val > 0) {
        settings.ceus_total = val || null;
      } else if (selectedOutcomeType === 'learning_hours' && val > 0) {
        settings.learning_hours_total = parseInt(val, 10);
        settings.learning_seconds_total = settings.learning_hours_total ? FormatUtil.convertHoursToSeconds(settings.learning_hours_total) : null;
      }
      setValue(section, settings);
    }
  };
  const learningHours = selectedOutcomeSettings.learning_seconds_total ? Math.round(1 * FormatUtil.convertSecondsToHours(selectedOutcomeSettings.learning_seconds_total)) : '';
  const OUTCOME_OPTIONS = useMemo(() => {
    const options = [
      {
        text: 'Prepare for a job role',
        value: 'career_program',
      },
      {
        text: 'Prepare for a certification exam',
        value: 'cert_prep_program',
      },
      {
        text: 'Learn a new skill',
        value: 'skill_program',
      },
      {
        text: 'Assess my team',
        value: 'team_assessment',
      },
      {
        text: 'Complete a minimum number of learning hours',
        value: 'learning_hours',
      },
      {
        text: 'Earn CEUs',
        value: 'ceus',
      },
      {
        text: 'Onboard a new team member',
        value: 'onboarding',
      },
      {
        text: 'Other',
        value: 'other',
      },
    ];

    if (selectedOutcomeType === 'team_baseline') {
      options.push({
        text: 'Baseline my team',
        value: 'team_baseline',
      });
    }

    if (selectedOutcomeType === 'recommended_learning') {
      options.push({
        text: 'Complete recommended learning',
        value: 'recommended_learning',
      });
    }

    return options;
  }, [selectedOutcomeType]);

  return (
    <WizardOutcomeSelection
      selectedOutcomeType={selectedOutcomeType}
      selectedOutcomeSettings={selectedOutcomeSettings}
      isEditView={isEditView}
      outcomeOptions={OUTCOME_OPTIONS}
      inputRef={inputRef}
      handleSelection={handleSelection}
      learningHours={learningHours}
      goalType="team"
    />
  );
}

export function GoalLearningActivity({
  setValue,
  selectedContentType,
  selectedContentIds,
  paths,
  getLearningActivities,
  learningActivityOptions,
  getPaths,
  selectedOutcomeType,
  filterOptions,
  isEditView,
}) {
  const inputRef = useRef();
  const [pathsModal, setPathsModal] = useState(false);
  const [tabSelection, setTabSelection] = useState('All');
  const handleClick = (section, val, fullSelections) => {
    setValue(section, val);
    if (section === 'content_type') {
      setValue('content_ids', []);
      setValue('contents', []);
    } else {
      setValue('contents', fullSelections);
    }
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 200);
  };
  const createNewPathCallback = (path) => {
    getPaths();
    setValue('content_type', 'path');
    setValue('content_ids', [path.id]);
    setValue('contents', [{ title: path.name }]);
    setPathsModal(false);
  };

  const getActivityOptions = () => {
    const options = [];

    if (selectedOutcomeType === 'team_assessment' || selectedOutcomeType === 'team_baseline') {
      options.push(
        ...[
          {
            text: 'Assessment Path',
            value: 'assessment_path',
          },
          {
            text: 'Assessment',
            value: 'assessment',
          },
        ]
      );
    } else if (selectedOutcomeType === 'career_program') {
      options.push(
        ...[
          {
            text: 'Career Path',
            value: 'career_program',
          },
        ]
      );
    } else if (selectedOutcomeType === 'skill_program') {
      options.push(
        ...[
          {
            text: 'Skill Path',
            value: 'skill_program',
          },
        ]
      );
    } else if (selectedOutcomeType === 'cert_prep_program') {
      options.push(
        ...[
          {
            text: 'Certification Prep',
            value: 'cert_prep_program',
          },
        ]
      );
    } else {
      options.push(
        ...[
          {
            text: 'Path',
            value: 'path',
          },
          {
            text: 'Course or learning activity',
            value: 'activity',
          },
        ]
      );
    }

    if (selectedOutcomeType === 'ceus' || selectedOutcomeType === 'learning_hours') {
      options.push({
        text: 'Learner Decides',
        value: 'none',
      });
    }

    return options;
  };

  const isProgramPathOutcome = useMemo(
    () => selectedOutcomeType === 'career_program' || selectedOutcomeType === 'skill_program' || selectedOutcomeType === 'cert_prep_program',
    [selectedOutcomeType]
  );

  const isPathContent = useMemo(
    () =>
      selectedContentType === 'path' ||
      selectedContentType === 'assessment_path' ||
      selectedContentType === 'career_program' ||
      selectedContentType === 'skill_program' ||
      selectedContentType === 'cert_prep_program',
    [selectedContentType]
  );

  const isActivityContent = useMemo(() => selectedContentType === 'activity' || selectedContentType === 'assessment', [selectedContentType]);

  const filteredPaths = useMemo(() => {
    if (selectedOutcomeType === 'team_assessment' || selectedOutcomeType === 'team_baseline') {
      return paths.data.filter((path) => path.type === 'Assessment Path' && path.status === 'Active');
    }

    if (selectedOutcomeType === 'career_program' || selectedOutcomeType === 'skill_program' || selectedOutcomeType === 'cert_prep_program') {
      return paths.data.filter((path) => path.class === selectedOutcomeType && path.status === 'Active');
    }

    if (tabSelection !== 'All') {
      return paths.data.filter((path) => path.metaLabels.indexOf(tabSelection) > -1);
    }

    return paths.data;
  }, [tabSelection, paths.data, paths.metaLabels, selectedOutcomeType]);

  const tabs = useMemo(
    () => (isProgramPathOutcome || selectedOutcomeType === 'team_assessment' || selectedOutcomeType === 'team_baseline' ? [] : ['All', 'Custom', 'Cybrary']),
    [selectedOutcomeType, isProgramPathOutcome]
  );

  const searchContentType = useMemo(() => {
    if (selectedContentType === 'assessment') {
      return 'Assessment';
    }
    return null;
  }, [selectedContentType]);

  const subtitle = useMemo(
    () => (selectedOutcomeType === 'team_assessment' ? 'Search for an assessment from the catalog.' : 'Search for a course or learning activity from the catalog'),
    [selectedOutcomeType]
  );

  useEffect(() => {
    if (isActivityContent && !learningActivityOptions.data.length) {
      getLearningActivities(' ', searchContentType);
    }
  }, [isActivityContent]);

  return (
    <>
      <SelectBoxes
        containerClasses="overflow-y-auto w-11/12"
        disabled={isEditView}
        selections={selectedContentType}
        handleSelect={(selection, fullSelections) => handleClick('content_type', selection, fullSelections)}
        options={getActivityOptions()}
      />
      {/* Note: Getting array index 0 for default as selectedContentIds will only ever be one item, but comes in an array */}
      <If condition={isPathContent}>
        <>
          <Divider marginTop="mt-6" marginBottom="mb-6" />
          <div ref={inputRef} />
          <If condition={!isProgramPathOutcome}>
            <p className="text-sm text-gray-600">
              Select a path, or{' '}
              <button className="text-cyb-pink-500 hover:text-black cursor-pointer" onClick={() => setPathsModal(true)}>
                click here
              </button>{' '}
              to add a new one
            </p>
          </If>
          <SelectBoxes
            containerClasses="overflow-y-auto w-11/12"
            disabled={isEditView}
            returnFullSelections
            handleSearch={(query) => filterOptions('paths', query)}
            selections={selectedContentIds?.[0]}
            handleSelect={(selection, fullSelections) => handleClick('content_ids', [selection], fullSelections)}
            options={filteredPaths}
            loading={paths.loading}
            tabs={tabs}
            activeTab={tabSelection}
            tabClick={setTabSelection}
          />
          <Modal open={pathsModal} toggle={() => setPathsModal(false)} ariaLabelledBy="paths-modal" size="lg">
            <div className="px-8 text-black bg-white">
              <CurriculumBuilder onSubmit={createNewPathCallback} />
            </div>
          </Modal>
        </>
      </If>
      <If condition={isActivityContent}>
        <>
          <Divider marginTop="mt-6" marginBottom="mb-6" />
          <div ref={inputRef} />
          <p className="mb-4 text-sm text-gray-600">{subtitle}</p>
          <div className="mb-4">
            <SearchInput placeholder="Type to search..." onChange={(e) => getLearningActivities(e.target.value, searchContentType)} />
          </div>
          <SelectBoxes
            containerClasses="overflow-y-auto w-11/12"
            disabled={isEditView}
            returnFullSelections
            selections={selectedContentIds[0]}
            handleSelect={(selection, fullSelections) => handleClick('content_ids', [selection], fullSelections)}
            options={learningActivityOptions.data}
          />
        </>
      </If>
    </>
  );
}

export function GoalDetails({ descriptionValue, nameValue, setValue }) {
  return (
    <div>
      <p className="mb-4 text-sm text-gray-600">Give your goal a name. Something that&apos;ll help your team remember what they are trying to accomplish.</p>
      <input
        className="p-2 mb-6 rounded-sm border border-gray-400"
        defaultValue={nameValue || ''}
        onChange={(e) => setValue('name', e.target.value)}
        placeholder="Name your goal"
      />
      <p className="mb-4 text-sm text-gray-600">Add any additional details about this goal that you would like your team to know (optional).</p>
      <textarea
        className="p-2 w-full rounded-sm border border-gray-400"
        defaultValue={descriptionValue || ''}
        onChange={(e) => setValue('description', e.target.value)}
        placeholder="Add details"
      />
    </div>
  );
}

export function DetailsStepperDisplay({ name, description }) {
  const classes = description ? 'list-disc' : '';
  return (
    <ul>
      <li className={classes}>{name && `Title: ${name}`}</li>
      <li className={classes}>{description && `Details: ${FormatUtil.formatLongText(description, 22)}`}</li>
    </ul>
  );
}

const TeamsGoalsWizard = inject(
  'commonStore',
  'userStore',
  'goalsStore',
  'enterpriseStore',
  'teamHierarchyStore'
)(
  observer(
    class TeamsGoalsWizard extends Component {
      state = {
        submitting: false,
        pathsModalOpen: false,
        error: null,
        validationMap: {},
      };

      componentDidMount() {
        const { goalId, teamGroupId, queryParams, goalsStore } = this.props;
        if (goalId) {
          goalsStore.getGoalData(goalId, teamGroupId);
        }
        this.setGoalDataQueryParams(queryParams);
        this.setValidationMap();
      }

      componentWillUnmount() {
        this.props.goalsStore.setDefaultGoalData();
        this.props.goalsStore.setDefaultLearningActivities();
      }

      setGoalDataQueryParams = (params) => {
        const { setGoalData, findAndSetGoalData, goalData } = this.props.goalsStore;
        if (params && Object.keys(params).length) {
          // Loop through params and set values if applicable
          Object.keys(params).forEach((key) => {
            if (key in goalData.data) {
              const value = params[key];
              switch (key) {
                case 'outcome_type':
                  this.setValue('outcome_type', value);
                  this.setValue('outcome_settings', {});
                  break;
                case 'content_ids':
                  // split string into array of numbers
                  setGoalData(
                    'team',
                    'content_ids',
                    value.split(',').map((id) => 1 * id)
                  );
                  findAndSetGoalData('team', 'contents', 1 * value, params.content_type === 'path');
                  break;
                case 'participant_ids':
                  setGoalData('team', 'participant_ids', [1 * value]);
                  setGoalData('team', 'participant_type', 'user');
                  findAndSetGoalData('team', 'participants', { value: 1 * value });
                  break;
                case 'owner_ids':
                  setGoalData('team', 'owner_ids', [1 * value]);
                  setGoalData('team', 'owners', [{ value: 1 * value, text: 'Myself' }]);
                  break;
                default:
                  setGoalData('team', key, value);
                  break;
              }
            }
          });
        }
      };

      handleSubmit = (lastStep) => {
        const { onSaveGoal } = this.props;

        const err = this.checkForErrors(lastStep);
        if (!err) {
          if (onSaveGoal) {
            onSaveGoal();
          } else {
            this.saveGoal();
          }
        }
      };

      saveGoal = () => {
        const { goalId, teamGroupId } = this.props;
        const newState = {
          ...this.state,
          submitting: true,
        };
        this.setState(newState, () => {
          // If we have a goal id we are editing an existing goal
          this.props.goalsStore
            .saveGoal(goalId, teamGroupId)
            .then(() => {
              newState.submitting = false;
              this.setState(newState, () => {
                this.props.commonStore.triggerToast('success', { content: `Your goal has been ${goalId ? 'updated' : 'set!'}` });
                this.props.onSubmit();
              });
            })
            .catch(() => {
              newState.submitting = false;
              this.setState(newState);
              this.props.commonStore.triggerToast('error', { content: 'Something went wrong. Unable to save this goal at this time.' });
            });
        });
      };

      getOwnerDetails = (goalData) => {
        if (goalData.owners && goalData.owners.length) {
          const data = goalData.owners;
          return data.length > 1 ? `${data.length} Owners` : data[0].name || data[0].text;
        }
        return null;
      };

      getParticipantsDetails = () => {
        const { goalData } = this.props.goalsStore;
        const { team } = this.props;
        const { participants } = goalData.data;
        const participantType = GoalsUtil.getParticipantType(goalData.data, team);
        return GoalsUtil.getParticipantsDisplay(participantType, participants, team);
      };

      checkCanNavigate = (section, validationMap) => {
        return !!validationMap[section] && !!validationMap[section].valid;
      };

      checkForErrors = (fromSection, toSection) => {
        const errorCheck = GoalsUtil.checkSectionValid('team', this.props.goalsStore.goalData.data, fromSection.id);

        const newState = {
          ...this.state,
        };
        // If you're able to navigate to the destination section, hide any errors shown from current section
        if (toSection && !toSection.preventNavigation) {
          newState.error = null;
          this.setState(newState);
        } else if (!errorCheck.valid && errorCheck.error) {
          newState.error = errorCheck.error;
          this.setState(newState);
          return errorCheck.error;
        }
        return null;
      };

      setValue = (field, val) => {
        const { setGoalData } = this.props.goalsStore;
        const newState = {
          ...this.state,
          error: null,
        };
        // Clear out any errors visible
        this.setState(newState, () => {
          setGoalData('team', field, val);
          this.setValidationMap();
        });
      };

      setValidationMap = () => {
        const validationMap = GoalsUtil.getGoalValidationMap('team', this.props.goalsStore.goalData.data);
        const newState = {
          ...this.state,
          validationMap,
        };
        this.setState(newState);
      };

      render() {
        const { goalId, team, filterGroupsByPermissions } = this.props;
        const { trees, setHierarchySelectOptions } = this.props.teamHierarchyStore;
        const teamHierarchy = trees[team.id];
        const { user } = this.props.userStore;
        const { submitting, validationMap } = this.state;

        const { goalData, goalOwners, goalParticipants, paths, getPaths, filterOptions, getLearningActivities, learningActivities } = this.props.goalsStore;
        const {
          outcome_type,
          outcome_settings,
          owner_ids,
          participant_ids,
          content_type,
          content_ids,
          contents,
          due_type,
          due_date,
          due_interval,
          name,
          description,
          include_admins,
        } = goalData.data;
        const isEditView = !!goalId;
        const disabledContainerClasses = isEditView ? 'text-gray-600' : '';
        let startStep = isEditView ? 'name' : 'outcome_type';
        if (outcome_type === 'team_baseline') {
          startStep = 'owner_ids';
        }

        return (
          <div>
            <Wizard
              errorMessage={this.state.error}
              submitText="Save"
              isValid={!submitting && GoalsUtil.checkGoalValid('team', goalData.data)}
              onSubmit={this.handleSubmit}
              scrollToError
              startStep={startStep}
              steps={[
                {
                  id: 'outcome_type',
                  content: {
                    heading: 'Outcome',
                    description: <WizardStepDescription step="outcome_type" disabled={isEditView} goalType="team" />,
                    stepperDetails: !!validationMap.outcome_type && !!validationMap.outcome_type.valid && (
                      <WizardOutcomeStepperDetails
                        outcomeDisplay={GoalsUtil.getOutcomeDisplay(outcome_type, outcome_settings)}
                        practiceTestDisplay={GoalsUtil.getPracticeTestDetails(goalData.data)}
                      />
                    ),
                    contentContainerClasses: disabledContainerClasses,
                  },
                  additionalContent: <GoalOutcome setValue={this.setValue} selectedOutcomeType={outcome_type} selectedOutcomeSettings={outcome_settings} isEditView={isEditView} />,
                  preventNavigation: outcome_type === 'team_baseline',
                  onStepSwitch: this.checkForErrors,
                  isValid: validationMap.outcome_type && validationMap.outcome_type.valid,
                },
                {
                  id: 'owner_ids',
                  content: {
                    heading: 'Owners',
                    description: <WizardStepDescription step="owner_ids" disabled={isEditView} goalType="team" />,
                    stepperDetails: this.getOwnerDetails({ ...goalData.data }),
                    contentContainerClasses: disabledContainerClasses,
                  },
                  additionalContent: (
                    <WizardOwnersSelection
                      setValue={this.setValue}
                      owners={{ ...goalOwners }}
                      selectedOwnerIds={owner_ids}
                      filterOptions={filterOptions}
                      userId={user.id}
                      isEditView={isEditView}
                    />
                  ),
                  preventNavigation: !this.checkCanNavigate('outcome_type', validationMap),
                  onStepSwitch: this.checkForErrors,
                  isValid: validationMap.owner_ids && validationMap.owner_ids.valid,
                },
                {
                  id: 'participant_type',
                  content: {
                    heading: 'Participants',
                    description: <WizardStepDescription step="participant_type" disabled={isEditView} goalType="team" />,
                    stepperDetails: validationMap.participant_type && validationMap.participant_type.valid && this.getParticipantsDetails(),
                  },
                  additionalContent: (
                    <WizardParticipantsSelection
                      setValue={this.setValue}
                      selectedParticipantType={GoalsUtil.getParticipantType(goalData.data, team)}
                      selectedParticipantIds={participant_ids}
                      members={{ ...goalParticipants }}
                      groups={{ ...teamHierarchy }}
                      searchGroups={(query) => setHierarchySelectOptions(team.id, filterGroupsByPermissions, query, null, true)}
                      includeAdmins={include_admins}
                      team={team}
                      filterOptions={filterOptions}
                    />
                  ),
                  preventNavigation: !this.checkCanNavigate('owner_ids', validationMap),
                  onStepSwitch: this.checkForErrors,
                  isValid: validationMap.participant_type && validationMap.participant_type.valid,
                },
                {
                  id: 'content_type',
                  content: {
                    heading: 'Learning',
                    description: <WizardStepDescription step="content_type" disabled={isEditView} outcomeType={outcome_type} selectedContentType={content_type} goalType="team" />,
                    stepperDetails:
                      validationMap.content_type &&
                      validationMap.content_type.valid &&
                      GoalsUtil.getLearningActivityDisplay(content_type, contents, true, outcome_type === 'team_baseline'),
                    contentContainerClasses: disabledContainerClasses,
                  },
                  additionalContent: (
                    <GoalLearningActivity
                      setValue={this.setValue}
                      selectedContentType={content_type}
                      selectedContentIds={content_ids}
                      paths={{ ...paths }}
                      getLearningActivities={(val, contentType) => getLearningActivities(val, team.package_types, contentType)}
                      learningActivityOptions={{ ...learningActivities }}
                      getPaths={() => getPaths(team.id, team.package_types)}
                      selectedOutcomeType={outcome_type}
                      filterOptions={filterOptions}
                      isEditView={isEditView}
                    />
                  ),
                  preventNavigation: outcome_type === 'team_baseline' || !this.checkCanNavigate('participant_type', validationMap),
                  shouldSkipNavigation: outcome_type === 'team_baseline',
                  onStepSwitch: this.checkForErrors,
                  isValid: validationMap.content_type && validationMap.content_type.valid && content_ids?.length > 0,
                },
                {
                  id: 'start_date',
                  content: {
                    heading: 'Start Date',
                    description: <WizardStepDescription step="start_date" disabled={isEditView} goalType="team" />,
                    stepperDetails: validationMap.start_date && validationMap.start_date.valid && GoalsUtil.getStartDateDetails(goalData.data.start_date),
                  },
                  additionalContent: <WizardStartTimeSelection setValue={this.setValue} selectedStartDate={goalData.data.start_date} goalType="team" />,
                  preventNavigation: !this.checkCanNavigate('content_type', validationMap) || !content_ids?.length,
                  onStepSwitch: this.checkForErrors,
                  isValid: !validationMap.start_date || validationMap.start_date.valid,
                },
                {
                  id: 'due_type',
                  content: {
                    heading: 'Deadline',
                    description: <WizardStepDescription step="due_type" disabled={isEditView} goalType="team" />,
                    stepperDetails: validationMap.due_type && validationMap.due_type.valid && GoalsUtil.getDeadlineDetails(due_type, due_date, due_interval),
                  },
                  additionalContent: (
                    <WizardTimeframeSelection
                      setValue={this.setValue}
                      selectedDueType={due_type}
                      selectedDueDate={due_date}
                      selectedDueInterval={due_interval}
                      omitRecurringOption={['ceus', 'learning_hours'].indexOf(outcome_type) === -1} // Only show recurring option if outcome is CEUs or learning hours
                      goalType="team"
                    />
                  ),
                  preventNavigation: outcome_type === 'team_baseline' || !this.checkCanNavigate('start_date', validationMap),
                  shouldSkipNavigation: outcome_type === 'team_baseline',
                  onStepSwitch: this.checkForErrors,
                  isValid: (validationMap.due_type && validationMap.due_type.valid) || outcome_type === 'team_baseline',
                },
                {
                  id: 'name',
                  content: {
                    heading: 'Name & Details',
                    stepperDetails: <DetailsStepperDisplay name={name} description={description} />,
                  },
                  additionalContent: <GoalDetails setValue={this.setValue} nameValue={name} descriptionValue={description} />,
                  preventNavigation: !this.checkCanNavigate('due_type', validationMap),
                  onStepSwitch: this.checkForErrors,
                  isValid: validationMap.name && validationMap.name.valid,
                },
              ]}
            />
          </div>
        );
      }
    }
  )
);

export default TeamsGoalsWizard;
