import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import withRouter from '../../components/Router/withRouter';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import TeamOwnerActivityLog from '../../components/AdminTeamActivityLogs/TeamOwnerActivityLog';
import ActionUtil from '../../utils/actionsUtil';

const ActivityLog = inject(
  'commonStore',
  'authStore',
  'userStore'
)(
  observer(({ commonStore, authStore, userStore, match }) => {
    const { team } = userStore;
    const { params } = match;
    const isDemo = params.orgId === 'demo';

    useEffect(() => {
      if (isDemo) {
        userStore.enterDemo();
      } else {
        userStore.setPreferredTeamInit('', params.orgId);
      }
      commonStore.setPageTitle(`Audit Log${isDemo ? ' Demo' : ''} | Cybrary`);
      authStore.fireAttributionEvent();
      // scroll to top
      ActionUtil.scrollToTop();
    }, []);

    return (
      <Container size="lg">
        <Title title="Audit Log" />
        <TeamOwnerActivityLog team={team} />
      </Container>
    );
  })
);

export default withRouter(ActivityLog);
