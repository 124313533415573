import { ProviderId } from 'firebase/auth';
import { twMerge } from 'tailwind-merge';
import ALL_COUNTRIES from './utils/countries';

export const CONTENT_TYPES = {
  COURSE: 1,
  VIDEO_ACTIVITY: 2,
  MODULE: 3,
  ARTICLE_ACTIVITY: 4,
  CURRICULUM: 5,
  CAREER_PATH: 6,
  JOB_DESCRIPTION: 7,
  ASSESSMENT: 8,
  ASSESSMENT_QUESTION: 9,
  ASSESSMENT_CYBERSKYLINE: 10,
  LAB_PRACTICE_LABS: 11,
  LAB_CYBERSCORE: 12,
  ASSESSMENT_MICRO_COURSE: 13,
  PRACTICE_TEST_CYBERVISTA: 14, // THIS IS THE CYBERVISTA LAB TYPE
  ASSESSMENT_CYDEFE: 15,
  LAB_RANGEFORCE: 16,
  CAREER_PATH_LEGACY: 17,
  EXAM_PRACTICE_LABS: 18,
  CYBRARY_LIVE_SESSION: 19,
  CYBRARY_LIVE_SERIES: 20,
  TEST_IMOCHA: 21,
  LEARN_ON_DEMAND_PRO: 22, // THIS IS NOW SKILLABLE
  LEARN_ON_DEMAND_SELF: 23,
  MODULE_PRACTICE_LABS: 24,
  COURSE_NEXT_TECH: 25,
  ACTIVITY_NEXT_TECH: 26,
  CHALLENGE_NEXT_TECH: 27,
  LESSON_NEXT_TECH: 28,
  PROJECT_NEXT_TECH: 29,
  QUIZ_NEXT_TECH: 30,
  READING_NEXT_TECH: 31,
  ASSESSMENT_RANGEFORCE: 32,
  ASSESSMENT_LEARN_ON_DEMAND: 33,
  LAB_INFOSEC_LEARNING: 34,
  COURSE_AVATAO: 35,
  CHALLENGE_AVATAO: 36,
  TUTORIAL_AVATAO: 37,
  LESSON_ACTIVITY: 38,
  THREAT_ACTOR_PATH: 39,
  CYBRARY_LAB: 40,
  CERTIFICATION_PREP: 41,
  LIVE_TRAINING_COURSE: 42,
  CYBRARY_SELECT_PATH: 43,
  CYBRARY_LAB_COURSE: 44,
  CYBRARY_ASSESSMENT: 45,
  CYBRARY_ASSESSMENT_COURSE: 46,
  CYBRARY_ASSESSMENT_PATH: 47,
  COLLECTION: 48,
  CYBRARY_LAB_V2: 49,
  CAREER_PROGRAM: 50,
  CHALLENGE: 51,
  SKILL_PROGRAM: 52,
  CERT_PREP_PROGRAM: 53,
};

export const COUNTRIES = ALL_COUNTRIES;

/** Roles shared in both cip & teams form... for now... */
export const SHARED_ROLES = [
  {
    text: 'C-Suite Executive',
    value: 'C-Suite Executive',
  },
  {
    text: 'SVP, VP, Department Head',
    value: 'SVP, VP, Department Head',
  },
  {
    text: 'Director, Sr. Director',
    value: 'Director, Sr. Director',
  },
  {
    text: 'Manager, Sr. Manager',
    value: 'Manager, Sr. Manager',
  },
  {
    text: 'Infosec Generalist',
    value: 'Infosec Generalist',
  },
  {
    text: 'Architect',
    value: 'Architect',
  },
  {
    text: 'Analyst',
    value: 'Analyst',
  },
  {
    text: 'Engineer',
    value: 'Engineer',
  },
  {
    text: 'Developer',
    value: 'Developer',
  },
];

/** Departments shared in both cip & teams form... for now... */
export const SHARED_DEPARTMENTS = [
  {
    text: 'Data Science',
    value: 'Data Science',
  },
  {
    text: 'Engineering',
    value: 'Engineering',
  },
  {
    text: 'IT',
    value: 'IT',
  },
  {
    text: 'Learning & Development',
    value: 'Learning & Development',
  },
  {
    text: 'Operations',
    value: 'Operations',
  },
  {
    text: 'QA/Testing',
    value: 'QA/Testing',
  },
  {
    text: 'Risk & Compliance',
    value: 'Risk & Compliance',
  },
  {
    text: 'Sales Engineering',
    value: 'Sales Engineering',
  },
  {
    text: 'Sales Operation',
    value: 'Sales Operation',
  },
  {
    text: 'Security',
    value: 'Security',
  },
  {
    text: 'Support/Help Desk',
    value: 'Support/Help Desk',
  },
  {
    text: 'Technical Marketing',
    value: 'Technical Marketing',
  },
];
export const TEAM_LEADS = [
  {
    text: 'I lead practitioners',
    value: 'I lead practitioners',
  },
  {
    text: 'I lead a team of managers',
    value: 'I lead a team of managers',
  },
  {
    text: 'Both',
    value: 'Both',
  },
  {
    text: 'No',
    value: 'No',
  },
];

export const TEAM_LEAD_ROLES = ['Both', 'I lead practitioners', 'I lead a team of managers'];
export const PRACTITIONER_ROLES = ['Infosec Generalist', 'Architect', 'Analyst', 'Engineer', 'Developer'];
export const MANAGER_PLUS_ROLES = ['C-Suite Executive', 'SVP, VP, Department Head', 'Director, Sr. Director', 'Manager, Sr. Manager'];

export const DEFAULT_COOKIE_SETTINGS = {
  domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.cybrary.it',
  secure: process.env.NODE_ENV === 'development' ? null : true,
  sameSite: process.env.NODE_ENV === 'development' ? null : 'lax',
};

// GeoIP lookup constants used in region-based pricing
export const GEO_LOOKUP_URL = 'https://8dkfarln27.execute-api.us-east-2.amazonaws.com/Prod/geoip';
export const GEO_COOKIE_NAME = 'cyb_geo';
export const GEO_DEFAULT_DATA = { planRegion: 'US', isDefaultGeoData: true };
export const GEO_COOKIE_PARAMS = {
  ...DEFAULT_COOKIE_SETTINGS,
  path: '/',
  expires: 7,
};
// Chargebee Plan IDs
export const CHARGEBEE_CIP_ANNUAL = 'cyb-insider-pro-1-yr';
export const CHARGEBEE_CIP_MONTHLY = 'cyb-insider-pro-1-mo';
export const CHARGEBEE_TEAM_ANNUAL = 'small-business-annual';
export const CHARGEBEE_CIP_ANNUAL_INDIA = 'cyb-insider-pro-1-yr_india';
export const CHARGEBEE_CIP_MONTHLY_INDIA = 'cyb-insider-pro-1-mo_india';
export const CHARGEBEE_TEAM_ANNUAL_INDIA = 'small-business-annual_india';
export const CHARGEBEE_ANNUAL_TEAMS_PLANS = [CHARGEBEE_TEAM_ANNUAL, CHARGEBEE_TEAM_ANNUAL_INDIA];
export const CHARGEBEE_ANNUAL_CIP_PLANS = [CHARGEBEE_CIP_ANNUAL, CHARGEBEE_CIP_ANNUAL_INDIA];
export const CHARGEBEE_MONTHLY_CIP_PLANS = [CHARGEBEE_CIP_MONTHLY, CHARGEBEE_CIP_MONTHLY_INDIA];
export const CHARGEBEE_ANNUAL_PLANS = [...CHARGEBEE_ANNUAL_TEAMS_PLANS, ...CHARGEBEE_ANNUAL_CIP_PLANS];
export const CHARGEBEE_REGIONS = {
  US: {
    cip: {
      monthly: CHARGEBEE_CIP_MONTHLY,
      annual: CHARGEBEE_CIP_ANNUAL,
    },
    team: {
      annual: CHARGEBEE_TEAM_ANNUAL,
    },
  },
  IN: {
    cip: {
      monthly: CHARGEBEE_CIP_MONTHLY_INDIA,
      annual: CHARGEBEE_CIP_ANNUAL_INDIA,
    },
    team: {
      annual: CHARGEBEE_TEAM_ANNUAL_INDIA,
    },
  },
};
/**
 * PADDLE
 */
export const PADDLE_COMPONENT_ID = 'paddle-wrapper';
export const PADDLE_PRICE_ID_CIP_MONTHLY = process.env.REACT_APP_PADDLE_PRICE_ID_CIP_MONTHLY;
export const PADDLE_PRICE_ID_CIP_ANNUAL = process.env.REACT_APP_PADDLE_PRICE_ID_CIP_ANNUAL;
export const PADDLE_PRICE_ID_TEAM_ANNUAL = process.env.REACT_APP_PADDLE_PRICE_ID_TEAM_ANNUAL;
export const PADDLE_DISCOUNT_ID_CIP_MONTHLY = process.env.REACT_APP_PADDLE_DISCOUNT_ID_CIP_MONTHLY;
export const PADDLE_DISCOUNT_ID_CIP_ANNUAL = process.env.REACT_APP_PADDLE_DISCOUNT_ID_CIP_ANNUAL;
export const PADDLE_DISCOUNT_ID_CIP_EDU_MIL_GOV = process.env.REACT_APP_PADDLE_DISCOUNT_ID_CIP_EDU_MIL_GOV;
export const PADDLE_SUBSCRIPTIONS = {
  cip: {
    monthly: PADDLE_PRICE_ID_CIP_MONTHLY,
    annual: PADDLE_PRICE_ID_CIP_ANNUAL,
  },
  team: {
    annual: PADDLE_PRICE_ID_TEAM_ANNUAL,
  },
};
/** Default Teams cart items */
export const TEAMS_CHECKOUT_DEFAULT_ITEMS = [
  {
    priceId: PADDLE_PRICE_ID_TEAM_ANNUAL,
    quantity: 5,
  },
];
/** Default CIP cart items */
export const CIP_CHECKOUT_DEFAULT_ITEMS = [
  {
    priceId: PADDLE_PRICE_ID_CIP_MONTHLY,
    quantity: 1,
  },
];
export const CIP_CHECKOUT_DEFAULT_ANNUAL_ITEMS = [
  {
    priceId: PADDLE_PRICE_ID_CIP_ANNUAL,
    quantity: 1,
  },
];
export const CIP_PLAN_QUANTITY = 1;
export const PATH_OMITTED_CONTENT_TYPES = [
  'Career Path',
  'Career Program',
  'Skill Path',
  'Certification Prep',
  'Cert Prep',
  'Assessment Path',
  'Threat Actor Path',
  'Collection',
  'Cybrary Select',
];
export const PUBLIC_PAGES = [
  '/verification-code',
  '/saml-result',
  '/forgot-password',
  '/reset-password',
  '/link-account',
  '/login',
  '/account-change',
  '/email-login',
  '/acctmgmt',
];

/*
  userTeams from the userStore has a key "role" which includes 'team-reporting-admin', 'team-admin', 'org-owner', 'team-member'
  role can be team-member but if you are a group admin or group reporting admin you can still view team dashboard
*/
export const TEAMS_DASHBOARD_ACCESS_ROLES = ['team-reporting-admin', 'team-admin', 'org-owner'];

export const CURRENCY_SYMBOLS = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: 'AR$',
  AUD: 'AU$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: 'BB$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: 'BM$',
  BND: 'BN$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: 'BS$',
  BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BYR: 'Br',
  BZD: 'BZ$',
  CAD: 'CA$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: 'CL$',
  CNH: '¥',
  CNY: '¥',
  COP: 'CO$',
  COU: 'COU',
  CRC: '₡',
  CUC: 'CU$',
  CUP: '₱',
  CVE: 'CV$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: 'FJ$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: 'GY$',
  HKD: 'HK$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: 'KY$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: 'LR$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: 'MX$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: 'NA$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: 'NZ$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: 'SB$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: 'SR$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: 'SV$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: 'TV$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYI: 'UYI',
  UYU: '$U',
  UYW: 'UYW',
  UZS: 'лв',
  VEF: 'Bs',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: 'XC$',
  XOF: 'CFA',
  XPF: '₣',
  XSU: 'Sucre',
  XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWD: 'Z$',
  ZWL: 'ZW$',
};
export const BASE_BUTTON_STYLES =
  'inline-block shrink-0 py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white rounded-sm bg-cyb-pink-500 hover:bg-pink-600';

export const SHARED_TOPIC_BUTTON_STYLES = twMerge('px-1 py-1.5 text-xs font-normal rounded-md min-w-[80px]');
export const SECONDARY_BUTTON_STYLES = 'inline-block shrink-0 py-2.5 px-6 text-sm font-bold leading-5 text-center text-gray-900 rounded-sm bg-transparent hover:text-black';

export const OAUTH_PROVIDERS = [
  {
    id: 'gg',
    name: 'Google',
    providerId: ProviderId.GOOGLE,
    logo: 'gg-logo',
  },
  {
    id: 'ap',
    name: 'Apple',
    providerId: 'apple.com',
    logo: 'ap-logo',
  },
  {
    id: 'ms',
    name: 'Microsoft',
    providerId: 'microsoft.com',
    logo: 'ms-logo',
  },
  {
    id: 'fb',
    name: 'Facebook',
    providerId: ProviderId.FACEBOOK,
    logo: 'fb-logo',
  },
  {
    id: 'ld',
    name: 'LinkedIn',
    providerId: 'linkedin.com',
    logo: 'ld-logo',
  },
  {
    id: 'saml',
    name: 'saml',
    providerId: 'saml.t_',
    logo: 'saml-logo',
  },
];

export const USERPILOT_EVENTS = {
  FINISHED_ONBOARDING: 'Finished Onboarding',
  VIEWED_BASELINE_INDIVIDUAL_REPORT: 'Viewed Baseline Individual Report',

  // IMMERSIVE
  LEFT_IMMERSIVE: 'Left Immersive',
  IMMERSIVE_INTERSTITIAL: 'Immersive Interstitial',
  IMMERSIVE_STARTED_ACTIVITY: 'Immersive Started Activity',
  IMMERSIVE_COMPLETED_ACTIVITY: 'Immersive Completed Activity',

  // TEAMS
  VIEWED_TEAM_DASHBOARD: 'Viewed Team Dashboard',
  VIEWED_DEMO_TEAM_DASHBOARD: 'Viewed Demo Team Dashboard',

  // PROMOS
  CIP_BANNER_CLICKED_ADD_EMAIL: 'CIP Banner Clicked Add Email',
  CIP_BANNER_CLICKED_UPGRADE: 'CIP Banner Clicked Upgrade',
};

export const CONFETTI_COLORS = ['#0db350', '#e2037a', '#1a6ef4', '#e2037a', '#ECC94B'];

const HELP_BASE_URL = 'https://help.cybrary.it/hc/en-us';

export const HELP_DESK_LINKS = {
  HOMEPAGE: HELP_BASE_URL,
  EDU_MIL_GOV_UPGRADE: `${HELP_BASE_URL}/articles/29027133843603-Cybrary-Impact-Hub-Discount-Program`,
  VENDOR_REPORTING_PROGRESS: `${HELP_BASE_URL}/articles/1500012804562-Cybrary-Vendor-Reporting-Progress-Completion-Reporting`,
  ROLES_PERMS_AND_USER_ROLES: `${HELP_BASE_URL}/articles/360056805794-Roles-Permissions-User-Roles`,
  COMMITMENT_TO_WEB_ACCESSIBILITY: `${HELP_BASE_URL}/articles/1500002668302-Cybrary-s-Commitment-to-Web-Accessibility`,
  ADD_LEARNING_SCHEDULE_TO_CALENDAR: `${HELP_BASE_URL}/articles/1500011421001-Adding-Your-Learning-Schedule-to-Your-Calendar`,
  HOW_TO_REGISTER_AND_LOGIN: `${HELP_BASE_URL}/articles/18412675308691-How-to-Get-Started-Register-and-Login-to-Cybrary`,
  CYBRARY_FOR_TEAMS: `${HELP_BASE_URL}/sections/360010736014-Cybrary-for-Teams`,
};

export const LOADING_FACTS = [
  { icon: '', title: 'Did you know…', description: 'Cybrary has more than 1000 hands-on experiences in our catalog.' },
  { icon: '', title: 'Preparing for a certification?', description: 'Cybrary has practice tests to help you be successful.' },
  { icon: '', title: '', description: 'Our virtual labs are entirely browser based. No downloads, no additional software.' },
  { icon: '', title: 'New to the industry?', description: 'Our hands-on experiences provide multiple real world experiences in a single platform.' },
  { icon: '', title: '', description: 'Cybrary instructors are leading professionals, industry leaders, and subject matter experts.' },
];
