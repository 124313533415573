import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import withRouter from '../../components/Router/withRouter';
import Container from '../../components/Container/Container';
import DashboardGoals from '../../components/Goals/DashboardGoals';
import ContinueLearningWidget from '../../components/ContinueLearning/ContinueLearningWidget';
import ProfileWidget from '../../components/Dashboard/ProfileWidget';
import NewReleasesWidget from '../../components/Dashboard/NewReleasesWidget';
import CustomPathsWidget from '../../components/Dashboard/CustomPathsWidget';
import useQueryParams from '../../hooks/useQueryParams';
import BadgesWidget from '../../components/Dashboard/BadgesWidget';
import WidgetContainer from '../../components/Container/WidgetContainer';
import If from '../../components/If/If';

const Dashboard = inject(
  'commonStore',
  'dashboardStore',
  'userStore',
  'authStore',
  'userNotificationStore'
)(
  observer(({ commonStore, dashboardStore, userStore, authStore, userNotificationStore }) => {
    const navigate = useNavigate();
    const queryParams = useQueryParams();
    const checkFreshCip = () => {
      // Show toast for freshly purchased CIP users
      const freshCip = !!(queryParams.freshCip || queryParams.fresh);
      if (freshCip) {
        // trigger toaster to display
        commonStore.triggerToast('success', {
          content: 'Your Insider Pro membership has been activated!',
        });
        // remove the query string param so we don't fire this twice on a reload
        navigate('/', { replace: true });
      }

      // remove newSubscription in local storage if charging the user for the subscription was a success
      const newSubscription = userStore.fetchSubscriptionStatus();
      if (!!newSubscription && newSubscription.status === 'success') {
        userStore.removeSubscriptionStatus();
      }
    };

    useEffect(() => {
      // Scroll to top to ensure app isn't scrolled to a vertical position from previous page
      document?.getElementById('dashboard-top')?.scrollIntoView({ block: 'nearest' });

      authStore.fireAttributionEvent();
      commonStore.setPageTitle('Dashboard | Cybrary');
      checkFreshCip();

      // Check for unread notifications on mount
      userNotificationStore.checkForUnread();

      return () => {
        dashboardStore.resetCurrentEnrollment();
      };
    }, []);

    function PrimaryDashboardLayout() {
      return (
        <div id="primary-dashboard-container" className="mx-auto w-full lg:max-w-[75rem]">
          <div id="first-row" className="mt-4 lg:flex">
            <WidgetContainer primaryWidget size="3/5" omitPadding>
              <ContinueLearningWidget />
            </WidgetContainer>
            <WidgetContainer secondaryWidget size="2/5" omitPadding omitBorder omitBackground>
              <WidgetContainer omitPadding>
                <ProfileWidget />
              </WidgetContainer>
            </WidgetContainer>
          </div>

          <div id="second-row" className="mt-4 lg:flex ">
            <WidgetContainer primaryWidget className="h-[350px]" size="3/5">
              <BadgesWidget />
            </WidgetContainer>
            <WidgetContainer secondaryWidget className="h-[350px]" size="2/5" omitPadding>
              <DashboardGoals navigate={navigate} />
            </WidgetContainer>
          </div>
          <If condition={userStore.isEnterprise}>
            <div id="custom-paths-widget" className="mt-4">
              <WidgetContainer className="pb-0">
                <CustomPathsWidget />
              </WidgetContainer>
            </div>
          </If>
          <div id="new-releases-container" className="mt-4">
            <WidgetContainer className="min-h-[390px]">
              <NewReleasesWidget />
            </WidgetContainer>
          </div>
        </div>
      );
    }

    return (
      <Container size="dashboard" omitPadding className="px-4 pt-4 lg:max-w-[80rem]">
        <h1 id="dashboard-top" className="sr-only">
          Dashboard
        </h1>
        <PrimaryDashboardLayout />
      </Container>
    );
  })
);
export default withRouter(Dashboard);
