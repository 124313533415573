// Some utilities to help with dealing with data returned from Algolia search.
import Bugsnag from '@bugsnag/js';
import algoliasearch from 'algoliasearch';
import algoliasearchHelper from 'algoliasearch-helper';
import moment from 'moment';
import FormatUtil from './formatUtil';

// this controls the content_groups we fetch from algolia. Remove one of the items here, it doesn't get fetched... certificationPrep is appended
// separately in getExploreSearchItems
const searchCategories = [
  'all',
  'careerPath',
  'collections',
  'course',
  'new',
  'lab',
  'practiceTest',
  'challenges',
  'assessment',
  'assessmentPath',
  'skillPath',
  'coming',
  'certificationPrep',
  'threatActorCampaign',
  'program',
];
const searchLicense = [
  { label: 'All', value: '' },
  { label: 'Free', value: '1' },
];

export default class SearchUtil {
  /**
   * Build filters string from a key and an array of values
   *
   * @param {string} key - The key that we will use to filter on.
   * @param {Array} values - Array of values we will turn into filter values
   * @param {string} providedOp - The operator, either OR or AND (defaults to OR)
   * @returns {string} - The filters string. Looks like "<key>:<value1> OR <key>:<value2>" etc
   */
  static buildFilters(key, values, providedOp) {
    const op = providedOp || 'OR';
    const filters = [];
    for (let i = 0; i < values.length; i++) {
      filters.push(`${key}:${values[i]}`);
    }
    return filters.join(` ${op} `);
  }

  /**
   * Check user's content packages. If no avatao license, ruleContext = ['normal']. Rule set in Algolia to omit Avatao records
   * If there is an avatao license, ruleContext = ['avatao'], which has no rule in Algolia (so Avatao not omitted)
   */
  static getRuleContexts(teamPackages) {
    let contentPackages = teamPackages;
    if (!contentPackages) {
      const allPackages = localStorage.getItem('content-packages');
      contentPackages = allPackages && allPackages.length ? allPackages.split(',') : [];
    }
    return contentPackages && contentPackages.indexOf('avatao') > -1 ? ['avatao'] : ['normal'];
  }

  /**
   *
   * @param {any} userData User data / options to be evaluated for global filters
   */
  static getGlobalFiltersForUser(userData) {
    let filterString = '';
    if (userData) {
      const { isTeamsUser } = userData;
      if (!isTeamsUser) {
        filterString += `NOT ${SearchUtil.buildFilters('tags_info', ['Live Training'])}`;
      }
    }
    return filterString;
  }

  /**
   * Perform an algolia request, returning data on success, error on failure.
   *
   * @param {string} index - The index we are going to load data from.
   * @param {any} searchOptions - Options sent to algolia, can contain filters, hitsPerPage, etc. ex: { hitsPerPage: 10, filters: "objectID:123 OR tags_info:'CVE Series'" }
   * @param {string} query - An optional query string to pass to the search helper.
   * @param {any} configOptions - Options for configuring the config passed to algoliasearchHelper on init. ex: { providedPage, facets, userId, teamPackages, isTeamsUser }
   * @returns Promise - returns promise that resolves when the data is retrieved, rejecting on error.
   */
  static async fetchFromAlgolia(index, searchOptions, query, configOptions = {}) {
    const { providedPage, facets, userId, teamPackages, isTeamsUser } = configOptions;
    const algoliaSearchOptions = { ...searchOptions };

    if (configOptions !== undefined && algoliaSearchOptions !== undefined) {
      const globalUserFilters = SearchUtil.getGlobalFiltersForUser({ userId, teamPackages, isTeamsUser });
      if (algoliaSearchOptions?.filters) {
        algoliaSearchOptions.filters += ` AND ${globalUserFilters}`;
      } else {
        algoliaSearchOptions.filters = globalUserFilters;
      }
    }

    const client = algoliasearch(process.env.REACT_APP_INSTANTSEARCH_APP_ID, process.env.REACT_APP_INSTANTSEARCH_API_KEY);
    const page = providedPage || 0;

    const config = {};
    if (facets) {
      config.facets = facets;
    }
    if (userId) {
      config.userToken = `${userId}`;
      config.clickAnalytics = true;
    }

    // If this is a catalog index, check of we have an avatao license and set ruleContexts accordingly
    if (/(_content_descriptions|_courses)/.test(index)) {
      config.ruleContexts = SearchUtil.getRuleContexts(teamPackages);
    }
    const helper = algoliasearchHelper(client, index, config);

    if (query !== undefined) {
      helper.setQuery(query);
    }

    helper.setPage(page);

    try {
      const { content: data } = await helper.searchOnce(searchOptions);
      return data;
    } catch (error) {
      // Skip sending "Unreachable hosts" errors to Bugsnag
      // These are usually transient network issues and not actionable, but they spam Bugsnag
      const isNetworkError = error.message?.includes('Unreachable hosts');
      if (!isNetworkError) {
        Bugsnag.notify(error, (event) => {
          event.addMetadata('search params', { index, searchOptions, query, ...configOptions });
          // eslint-disable-next-line no-param-reassign
          event.context = 'fetch from algolia';
        });
      }
      throw error;
    }
  }

  static releasedAtBuffer() {
    return 1 * moment().format('X') - 60 * 75;
  }

  // Transform an Algolia Hit into a normalized object, with sensible defaults
  static transformHit(type, hit) {
    switch (type) {
      // Handle a bunch of synonymous cases
      case 'instructor':
      case 'company':
      case 'author':
      case 'vendor':
      case 'provider':
        return SearchUtil.transformProviderHit(hit);

      case 'catalog':
      default:
        return SearchUtil.transformCatalogHit(hit);
    }
  }

  static formatIsFree(isFree) {
    if (!isFree) {
      return 'Premium Experience';
    }
    return null;
  }

  // Transform a hit from our Catalog into a normalized object.
  static transformCatalogHit(hit) {
    const transformed = { ...hit };
    let author = 'Cybrary';
    if (hit.instructors_info && hit.instructors_info.length && hit.instructors_info[0].name) {
      author = hit.instructors_info[0].name;
    } else if (hit.author && hit.author.name) {
      author = hit.author.name;
    } else if (hit.vendor && hit.vendor.name) {
      author = hit.vendor.name;
    }
    let level = 'Intermediate';
    if (hit.level && hit.level.name) {
      level = hit.level.name;
    }
    let type = '';
    if (hit.content_type && hit.content_type.nice_name) {
      type = hit.content_type.nice_name;
    }
    transformed.license = this.getContentLicense(hit);

    return {
      id: transformed.objectID,
      title: transformed.title,
      image: transformed.thumbnail_url,
      description: transformed.short_description || transformed.long_description,
      longdescription: transformed.long_description || transformed.short_description,
      duration: transformed.duration_seconds ? FormatUtil.formatTime(transformed.duration_seconds, 'hm') : null,
      author,
      instructors: transformed.instructors_info,
      type,
      level,
      free: this.formatIsFree(transformed.license.is_free),
      permalink: transformed.permalink,
      status: transformed.status,
      activity_types: transformed.activity_types,
      _raw: transformed,
    };
  }

  // Transform a hit from our company and/or instructors into a normalized object.
  static transformProviderHit(hit) {
    return {
      id: hit.objectID,
      image: hit.avatar_url || hit.thumbnail_url,
      name: hit.name,
      title: hit.name, // alias for name for now
      description: hit.bio || hit.description,
      _raw: hit,
    };
  }

  // Helper function to update an item its license status based on the is_free flag.
  static getContentLicense(content) {
    return {
      is_free: !!content.is_free, // cast as boolean
    };
  }

  /**
   * Build a bunch of filters from a configuration object.
   *
   * @param {Object} filters - an object containing filters i.e. { filterKey: ['filter1', 'filter2'] }
   * @returns {string} ret - the properly built filters string, with AND applied between filter keys, and OR between filters of a given key
   */
  static filtersFromObject(filters) {
    let ret = '';
    const filterKeys = Object.keys(filters);
    for (let i = 0; i < filterKeys.length; i++) {
      const filterKey = filterKeys[i];
      let values = filters[filterKey];
      if (!Array.isArray(values)) {
        values = [values];
      }
      if (ret !== '') {
        ret += ' AND ';
      }
      ret += SearchUtil.buildFilters(filterKey, values);
    }
    return ret;
  }

  static getSearchCategories = () => {
    return searchCategories;
  };

  static getLicenseFacets = () => {
    return searchLicense;
  };

  // This belongs to the browse refined filtered section names
  static getSearchSectionNiceNames = (view) => {
    switch (view) {
      case 'careerPath':
        return 'Career Paths';
      case 'assessmentPath':
        return 'Assessment Paths';
      case 'skillPath':
        return 'Skill Paths';
      case 'collections':
        return 'Collections';
      case 'course':
        return 'Courses';
      case 'new':
        return 'New Releases';
      case 'coming':
        return 'Coming Soon';
      case 'lab':
        return 'Virtual Labs';
      case 'practiceTest':
        return 'Practice Tests';
      case 'challenges':
        return 'Challenges';
      case 'assessment':
        return 'Skill Assessments';
      case 'socAnalyst':
        return 'SOC Analyst';
      case 'vulnerabilityManagement':
        return 'Vulnerability Management';
      case 'riskManagement':
        return 'Risk Management';
      case 'freeWithPaidLabs':
        return 'Free Content';
      case 'certificationPrep':
        return 'Certification Prep';
      case 'threatActorCampaign':
        return 'Threat Actor Campaigns';
      default:
        return view;
    }
  };

  /**
   * Perform an algolia request, Getting promo data based on user params and section specified
   *
   * @param {string} section - section to retreive from Algolia Ads index
   * @param {obj} user - Userstore user object
   * @returns Promise - returns promise that resolves when the data is retrieved, rejecting on error.
   */
  static getPromoData = (section, user) => {
    let userType = 'open_users';
    if (user.is_cip) {
      userType = 'cip_users';
    } else if (user.is_enterprise) {
      userType = 'enterprise_users';
    }

    const registeredAt = !!user && user.registered_at ? moment(user.registered_at) : null;
    const currentDate = moment.utc();
    // If for some reason we can't find their registered_at date, default them to 10 so they get the standard banner
    const daysRegistered = registeredAt ? moment(currentDate).diff(registeredAt, 'days') : 10;
    const registeredThisWeekFreeUser = userType === 'open_users' && daysRegistered < 7;

    const adIndex = process.env.REACT_APP_INSTANTSEARCH_ADS_INDEX;
    let filters = SearchUtil.buildFilters('section', [section]);
    filters += ` AND ${SearchUtil.buildFilters('hidden', ['false'])}`;
    const registeredThisWeekFreeUserClause = registeredThisWeekFreeUser ? ' AND ' : ' AND NOT ';
    filters += registeredThisWeekFreeUserClause + SearchUtil.buildFilters('registered_this_week_free_user', ['true']);
    filters += ` AND ${SearchUtil.buildFilters(userType, ['true'])}`;
    const options = {
      hitsPerPage: 1,
      filters,
    };

    return SearchUtil.fetchFromAlgolia(adIndex, options);
  };

  static searchInsightEvent = (userId, queryID, objectID, positionIdx) => {
    if (!userId || !queryID || !objectID) {
      return null;
    }
    if (positionIdx) {
      window.aa('clickedObjectIDsAfterSearch', {
        userToken: `${userId}`,
        eventName: 'item_click_on_nav_search',
        index: process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX,
        queryID,
        objectIDs: [`${objectID}`],
        positions: [positionIdx],
      });
    } else {
      window.aa('convertedObjectIDsAfterSearch', {
        userToken: `${userId}`,
        index: process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX,
        eventName: 'enroll_conversion_on_app_page',
        queryID,
        objectIDs: [`${objectID}`],
      });
    }
    return false;
  };

  static getViewAllQueryParam = (section) => {
    let queryParam = `?view=${section}`;
    if (section === 'coming') {
      queryParam = `?status=Coming%20Soon`;
    } else if (section === 'new') {
      queryParam = '?sort=Newest';
    }
    return queryParam;
  };

  static getNewReleasesDateRangeQuery() {
    const currentDate = moment().endOf('day');
    const previousMonthDate = moment().subtract(1, 'month').startOf('day');
    return `${currentDate.unix()} TO ${previousMonthDate.unix()}`;
  }

  // adds display filter, release buffer, and content_group filters by category
  static getDefaultFiltersByCategory = (category) => {
    let filters = `${SearchUtil.buildFilters('display_in_catalog', ['true'])}`;
    if (!category || category === 'all') {
      return filters;
    }
    // These filters control what content is fetched from algolia for the browse refined filtered results
    const filtersConfig = {
      careerPath: ` AND (${SearchUtil.buildFilters('content_group', ["'Career Path'"])} OR ${SearchUtil.buildFilters('content_group', ["'Cybrary Select'"])})`,
      collections: ` AND ${SearchUtil.buildFilters('content_group', ["'Generic Collection'"])}`,
      course: ` AND ${SearchUtil.buildFilters('content_group', ['Course'])}`,
      lab: ` AND ${SearchUtil.buildFilters('content_group', ["'Virtual Lab'"])}`,
      assessment: ` AND ${SearchUtil.buildFilters('content_group', ['Assessment'])}`,
      assessmentPath: ` AND ${SearchUtil.buildFilters('content_group', ["'Assessment Path'"])}`,
      skillPath: ` AND ${SearchUtil.buildFilters('content_group', ["'Skill Path'"])}`,
      practiceTest: ` AND ${SearchUtil.buildFilters('content_group', ["'Practice Test'"])}`,
      challenges: ` AND ${SearchUtil.buildFilters('content_group', ["'Challenge'"])}`,
      certification: ` AND ${SearchUtil.buildFilters('categories_info', ['certifications'])}`,
      certificationPrep: ` AND ${SearchUtil.buildFilters('content_group', ["'Certification Prep'"])}`,
      program: ` AND ${SearchUtil.buildFilters('content_group', ["'Program'"])}`,
      coming: ` AND ${SearchUtil.buildFilters('status', ["'Coming Soon'"])}`,
      // exclude live training content group from new releases for all users
      new: ` AND ${SearchUtil.buildFilters('released_at_timestamp', [this.getNewReleasesDateRangeQuery()])}  AND NOT ${SearchUtil.buildFilters('content_group', [
        "'Live Training'",
      ])}`,
      threatActorCampaign: ` AND (${SearchUtil.buildFilters('content_group', ["'Purple Team Exercise'"])} OR ${SearchUtil.buildFilters('content_group', [
        "'Threat Actor Campaign'",
      ])})`,
    };
    filters += filtersConfig[category] || '';
    return filters;
  };
}
