import React from 'react';
import { inject, observer } from 'mobx-react';
import CareerProgramsProvider from './CareerProgramsProvider';
import CustomPathsProvider from './CustomPathsProvider';
import ForumsSidebarProvider from './ForumsSidebarProvider';
import ProfileProvider from './ProfileProvider';
import BadgeModalProvider from './BadgeModalProvider';
import CybMeasureProvider from './CybMeasureProvider';
import OnboardingProvider from './OnboardingProvider';

/**
 * Global Providers that wrap the entire application.
 * This exists to help keep App.js clean while wrapping the app in multiple providers.
 * @param {*} children - React children
 */
const GlobalProviders = inject(
  'userStore',
  'enrollmentStore'
)(
  observer(({ children, userStore, enrollmentStore }) => {
    return (
      <ProfileProvider userStore={userStore}>
        <OnboardingProvider user={userStore?.user}>
          <CustomPathsProvider userStore={userStore} enrollmentStore={enrollmentStore}>
            <BadgeModalProvider>
              <ForumsSidebarProvider>
                <CybMeasureProvider userStore={userStore}>
                  <CareerProgramsProvider user={userStore.user}>{children}</CareerProgramsProvider>
                </CybMeasureProvider>
              </ForumsSidebarProvider>
            </BadgeModalProvider>
          </CustomPathsProvider>
        </OnboardingProvider>
      </ProfileProvider>
    );
  })
);

export default GlobalProviders;
