import React, { createContext, useContext, useState, useMemo, useCallback, useEffect, useRef } from 'react';
import Bugsnag from '@bugsnag/js';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Userpilot } from 'userpilot';
import moment from 'moment';
import GaUtil from '../utils/gaUtil';
import Agents from '../agents/agents';
import PermalinkUtil from '../utils/permalinkUtil';
import { DEFAULT_COOKIE_SETTINGS, PUBLIC_PAGES, USERPILOT_EVENTS } from '../constants';
import { useAuth } from '../contexts/UseAuth';
import { trackSnowplowEvent } from '../utils/snowplowUtil';
import { useProfileContext } from './ProfileProvider';

const OnboardingContext = createContext();

// DO NOT trigger onboarding if on these pages
const BLACKLISTED_PAGES = [
  ...PUBLIC_PAGES,
  '/logout',
  '/onboarding/terms-of-service',
  '/onboarding/recovery-email',
  '/onboarding/international',
  '/upgrade/teams-checkout',
  '/upgrade/checkout',
  '/google-marketplace-onboarding',
];

export const LEGACY_ONBOARDING_STEPS = {
  INITIAL: 'initial',
  CIP_GOALS: 'cipGoals',
  CIP_EXPERIENCE: 'cipExperience',
  CIP_YEARS_OF_EXPERIENCE: 'cipYearsOfExperience',
  CIP_FORM: 'cipForm',
  TEAM_FORM: 'teamForm',
  SELECT_PACKAGE: 'selectPackage',
  DEMO_FORM: 'demoForm',
  CONTENT_RECOMMENDATIONS: 'contentRecommendations',
  NAVIGATE: 'navigate',
};

export const ONBOARDING_STEPS = {
  INITIAL: 'initial',
  CIP_FORM: 'cipForm',
  TEAM_FORM: 'teamForm',
  CONTENT_RECOMMENDATIONS: 'contentRecommendations',
};

export const ONBOARDING_OPTIONS = {
  membershipFor: {
    TEAM: 'team',
    CIP: 'cip',
  },
  cipGoal: {
    LAUNCH_MY_CAREER: 'Launch My Career',
    GET_A_CERTIFICATION: 'Get a Certification',
    UPSKILL_AND_PRACTICE: 'Upskill & Practice',
  },
  cipExperience: {
    NEW: "I'm brand new",
    EXPERIENCED: "I've got some IT experience",
  },
  cipYearsOfExperience: {
    LESS_THAN_2: '0-2 years',
    MORE_THAN_2: '2+ years',
  },
};

function OnboardingProvider({ children, user }) {
  const profileData = user?.onboarding_data || {};
  /** Hooks */
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();
  const { personalProfileData } = useProfileContext();
  const { username } = personalProfileData || {};

  const [step, setStep] = useState(ONBOARDING_STEPS.INITIAL);
  const [isSubmitting, setIsSubmitting] = useState(false); // used when saving data
  const [isLoading, setIsLoading] = useState(false); // used when reloading user data

  const [navigationLocation, setNavigationLocation] = useState('');
  const previousStep = useRef(step); // useRef to avoid state updates when values change

  /* Initial step */
  const [teamOrCip, setTeamOrCip] = useState('');

  /* CIP/Team Form Field data */
  const [savedFormFields, setSavedFormFields] = useState({});

  /** Recommendations step */
  const [cipGoal, setCipGoal] = useState('');

  /* Legacy fields */
  const [cipExperience, setCipExperience] = useState('');
  const [cipYearsOfExperience, setCipYearsOfExperience] = useState('');

  /** Abstractions */
  const isTeams = teamOrCip === ONBOARDING_OPTIONS.membershipFor.TEAM;
  const isOnboardingCompleted = useMemo(() => {
    if (!user?.id) {
      return false;
    }

    // LEGACY ONBOARDING HANDLING
    // Old Accounts tend to have old malformed data that can cause issues in production
    // For these we need to check if the user has a first name, if they do, they are considered onboarded
    const accountCreatedDate = new Date(user?.registered_at);
    const oneYearAgo = moment().subtract(1, 'year').toDate();
    const isOlderThanOneYear = accountCreatedDate < oneYearAgo;
    if (isOlderThanOneYear) {
      // Just make sure they have a first name, otherwise, they are considered incomplete and should onboard
      return !!user?.first_name;
    }

    // Onboarding is considered completed if the user has `membershipFor` set
    // This field should only be set when the user completes the onboarding flow
    // This allows prefilling the onboarding form with the user's data from checkout
    return !!profileData?.membershipFor;
  }, [user, profileData]);

  const saveOnboardingData = useCallback(
    async (formFields) => {
      try {
        setIsSubmitting(true);

        // !IMPORTANT!
        // Profile data contains the username so we need to re-save it
        // alongside out onboarding data in the personal object
        // We should have a username loaded from profile data
        let currentUsername = username;
        // If we don't have a username, try to get it from the profile data
        if (!currentUsername) {
          setIsLoading(true);
          const currentProfileData = await Agents.profile.getProfile();
          currentUsername = currentProfileData?.data?.personal?.username || '';
          setIsLoading(false);
        }
        const payload = {
          // default payload the backend accepts, however it does not want null values including empty strings
          personal: {
            firstName: '',
            lastName: '',
            country: '',
            username: '',
            phone: '', // only required for teams
          },
          // This field is the wild wild west... it can be anything... but it is required
          onboarding_data: {
            membershipFor: '', // required for both
            title: '', // required for both... referred to as "role" for cip and "title" for teams... but the key is the same
            goal: '', // required for both
            experienceLevel: '', // only for cip but can be null depending on flow/route
            teamLead: '', // is in one cip flow but not the other... is also in teams form...
            yearsExperience: '', // only for cip but can be null depending on flow/route
            department: '', // both cip and teams... quasi required
            company: '', // both cip and teams... quasi required
            business_email: '', // only teams
          },
        };

        /** Personal */
        payload.personal = {
          firstName: formFields.firstName,
          lastName: formFields.lastName,
          country: formFields.country,
          username: currentUsername,
          phone: formFields?.phone || '', // only required for teams, but conditionally required for cip team leads
        };

        /** Onboarding Data */
        payload.onboarding_data = {
          title: formFields.title,
          goal: isTeams ? '' : cipGoal,
          membershipFor: teamOrCip,
          teamLead: formFields.teamLead,
          company: formFields?.company || '', // only required for teams, but conditionally required for cip
          department: formFields?.department || '', // quasi required for both, but conditionally for cip team leads
        };

        /** TEAMS - Add Business Email */
        if (isTeams) {
          payload.onboarding_data.business_email = formFields?.business_email || '';
        }

        /** CIP - Add experience if present */
        if (!isTeams) {
          payload.onboarding_data.experienceLevel = formFields?.experienceLevel || '';
          payload.onboarding_data.yearsExperience = formFields?.yearsExperience || '';
        }

        // Save the onboarding data to profile
        await Agents.profile.updateProfile(payload);

        // Push new profile data to GA/GTM
        // These are old, I think the create_profile event is the one we want to use
        GaUtil.fireEvent('CourseEnrollment', 'PQL', 'Onboarding');
        GaUtil.pushEventToDataLayer({
          event: 'create_profile',
          userId: user?.id,
          profile_country: payload?.personal?.country || '',
          what_brings: isTeams ? 'Team' : 'Career',
          // title in our form is the equivalent of role in GA
          role: payload?.onboarding_data?.title || '',
        });
      } catch (error) {
        Bugsnag.notify(error, (errorEvent) => {
          const { errorMessage } = errorEvent.errors[0];
          // eslint-disable-next-line no-param-reassign
          errorEvent.context = `Onboarding Provider: ${errorMessage}`;
          errorEvent.addMetadata('saveOnboardingData', {
            cipGoal,
            cipExperience,
            cipYearsOfExperience,
            teamOrCip,
          });
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [teamOrCip, cipGoal, cipExperience, cipYearsOfExperience]
  );

  const switchToManager = useCallback(() => {
    setTeamOrCip(ONBOARDING_OPTIONS.membershipFor.TEAM);
    setCipGoal('');
    setCipExperience('');
    setStep(ONBOARDING_STEPS.TEAM_FORM);
  }, []);

  const exitOnboarding = useCallback(async () => {
    setIsLoading(true);

    // Do this first to get fresh user data
    await auth.refreshUser();

    // Track when a user exits onboarding. label is the step the modal was closed on.
    trackSnowplowEvent({ category: 'OnboardingStep', action: 'closedModal', label: step });

    // Fire off post-onboarding userpilot guide (if any)
    Userpilot.track(USERPILOT_EVENTS.FINISHED_ONBOARDING);

    setIsLoading(false);
  }, [auth, step]);

  const isOnboardingAllowedOnThisPage = useMemo(() => {
    // Do not show on teams invite pages
    const isTeamInvitePage = /^\/team-invite*/.test(location.pathname);
    const cleanPathName = PermalinkUtil.removeTrailingSlash(location.pathname);
    return !BLACKLISTED_PAGES.includes(cleanPathName) && !isTeamInvitePage;
  }, [location]);

  const shouldEnterOnboarding = useMemo(() => {
    return !isOnboardingCompleted && isOnboardingAllowedOnThisPage && !!user?.id;
  }, [isOnboardingCompleted, isOnboardingAllowedOnThisPage, user]);

  // Set a cookie to hide the username modal for 36 hours if it is this user's first time here
  useEffect(() => {
    if (!isOnboardingCompleted) {
      Cookies.set('hide_username_modal', true, {
        ...DEFAULT_COOKIE_SETTINGS,
        path: '/',
        // expires in 36 hours
        expires: 1.5,
      });
    }
  }, [isOnboardingCompleted]);

  // Handle tracking of onboarding steps
  useEffect(() => {
    if (step) {
      // Track the last step that was completed
      if (step !== previousStep.current) {
        trackSnowplowEvent({ category: 'OnboardingStep', action: 'completed', label: previousStep.current });
      }

      if (step === 'navigate') return;
      // Track the step that was shown
      trackSnowplowEvent({ category: 'OnboardingStep', action: 'shown', label: step });
      // Update the last step for next event fire
      previousStep.current = step;
    }
  }, [step]);

  // Handle entering onboarding
  useEffect(() => {
    if (shouldEnterOnboarding) {
      navigate('/onboarding');
    }
  }, [shouldEnterOnboarding, navigate]);

  // Try to fetch profile data if it is not present or loading
  useEffect(() => {
    if (!profileData && !isLoading) {
      setIsLoading(true);
      auth.refreshUser().finally(() => {
        setIsLoading(false);
      });
    }
  }, [profileData, isLoading, auth]);

  const values = useMemo(
    () => ({
      step,
      teamOrCip,
      cipGoal,
      cipExperience,
      cipYearsOfExperience,
      savedFormFields,
      navigationLocation,
      isSubmitting,
      isLoading,
      shouldEnterOnboarding,
      isOnboardingCompleted,
      setStep,
      setTeamOrCip,
      setCipGoal,
      setCipExperience,
      setCipYearsOfExperience,
      setSavedFormFields,
      switchToManager,
      saveOnboardingData,
      setNavigationLocation,
      exitOnboarding,
      user,
      profileData,
    }),
    [
      step,
      teamOrCip,
      cipGoal,
      cipExperience,
      cipYearsOfExperience,
      savedFormFields,
      navigationLocation,
      isSubmitting,
      isLoading,
      shouldEnterOnboarding,
      isOnboardingCompleted,
      setStep,
      setTeamOrCip,
      setCipGoal,
      setCipExperience,
      setCipYearsOfExperience,
      setSavedFormFields,
      switchToManager,
      saveOnboardingData,
      setNavigationLocation,
      exitOnboarding,
      user,
      profileData,
    ]
  );

  return <OnboardingContext.Provider value={values}>{children}</OnboardingContext.Provider>;
}

export const useOnboarding = () => useContext(OnboardingContext);
export default OnboardingProvider;
