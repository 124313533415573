import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import Icon from '../../components/Icon/Icon';
import Header from '../../components/Header/Header';
import { useAuth } from '../../contexts/UseAuth';
import Container from '../../components/Container/Container';
import agents from '../../agents/agents';
import Loading from '../../components/Loading/Loading';
import ActionUtil from '../../utils/actionsUtil';
/**
 * Intermediary logout page.
 */
const Logout = inject(
  'authStore',
  'commonStore'
)(
  observer(({ authStore, commonStore }) => {
    const auth = useAuth();
    useEffect(() => {
      // Hide navigation bar
      commonStore.hidePrimaryNav();
      ActionUtil.scrollToTop();

      authStore.fireAttributionEvent().then(async () => {
        // Logout through the backend first
        await agents.authGoogle.logout();

        // Then logout through the client
        auth.signout();
      });
    }, []);

    return (
      <Container className="flex flex-col justify-center items-center h-screen">
        <Header className="text-center">
          <Icon name="log-out" className="inline-block mr-2 w-14 h-14 text-black" />
          Logout In Process...
        </Header>
        <Loading className="w-16 h-16 border-8" />
      </Container>
    );
  })
);

export default Logout;
