import React, { useEffect, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import GaUtil from '../../utils/gaUtil';
import AuthUtil from '../../utils/authUtil';
import If from '../If/If';
import { HELP_DESK_LINKS, USERPILOT_EVENTS } from '../../constants';
import Container from '../Container/Container';

/**
 * Cyb colored gradient banner container
 * @param {*} param0
 * @returns
 */
export function GradientBanner({ className, children }) {
  const hoverClasses = 'transition transition-all duration-[4000ms] ease-in-out bg-[length:110%_110%] hover:bg-[length:200%_200%] bg-left-bottom hover:bg-right-bottom';
  const classes = useMemo(
    () => twMerge(`p-1 sm:p-2 mx-auto w-full text-white bg-gradient-to-r from-[#CF0469] to-[#25135F] font-bold min-h-[80px] flex item-center`, className, hoverClasses),
    [className]
  );
  return (
    <div className={classes}>
      <Container size="lg" className="items-center text-center md:flex ">
        {children}
      </Container>
    </div>
  );
}

/**
 * Determines what copy is shown based on the user goal
 * @param {String} goal user goal chosen during onboarding
 * @returns {BannerContent} { title: "", body: "" } object containing the content to show
 */
function getBannerContent(goal) {
  switch (goal) {
    case 'Launch My Career':
      return {
        title: 'Enroll In a Career Path',
        body: 'Take the next step in your cybersecurity career and sign up for Cybrary Insider Pro to unlock access to all of our career paths.',
      };
    case 'Get a Certification':
      return {
        title: 'Confidently Pass The Exam',
        body: 'Practice exams can help you improve your test scores by over 25%. Sign up for Cybrary Insider Pro and get unlimited access to practice exams and labs.',
      };
    case 'Upskill & Practice':
      return {
        title: 'Get Your Dream Role',
        body: 'Sign up for Cybrary Insider Pro to master the hands-on skills that security leaders are hiring for.',
      };
    default:
      return {
        title: `Cybrary Insider Pro`,
        body: `Upgrade to practice the hands-on skills employers are hiring for. Unlock access to practice tests, virtual labs, career paths, and much more.`,
      };
  }
}
/** CIP upgrade banner shown when a user selects "Here for myself" during the onboarding flow
 * Shown on the LIHP and in immersive.
 * @param {string} className - additional classes to apply to the banner
 * @param {boolean} inline - whether or not the banner should be inline with other content
 */
const CIPUpgradeBanner = inject(
  'userStore',
  'profileStore',
  'commonStore'
)(
  observer(({ userStore, profileStore, commonStore, className, inline = false }) => {
    const navigate = useNavigate();

    useEffect(() => {
      profileStore.getUserAccounts();
    }, []);

    const { isEnterprise, isCip, user } = userStore;
    const { goal, membershipFor, title } = user?.onboarding_data || {};

    const { data: accounts } = profileStore?.profileData?.accounts || {};
    const isEduGovMilUser = AuthUtil.isEduGovMilUser(accounts);

    const isStudent = title === 'Student';
    const isNewReleasePromoEnabled = false; // Hardcode this value for now. Eventually this should live in contentful so it can be toggled on/off
    const isEduGovMilPromoEnabled = isStudent || isEduGovMilUser;

    const content = getBannerContent(goal);

    // remember that a user can be free but also enterprise... (on a team but has no license)
    // we want to show for all free users who aren't cip or enterprise users
    const shouldNotShow = isCip || isEnterprise || membershipFor === 'team';
    const isInTeamsDemo = userStore?.teamData?.id === 'demo';

    useEffect(() => {
      if (!inline) {
        if (shouldNotShow) {
          commonStore.removeCybRouterClass('upgrade-banner-adjustment');
        } else {
          commonStore.addCybRouterClass('upgrade-banner-adjustment');
        }
      }
    }, [shouldNotShow]);

    if (shouldNotShow) {
      return null;
    }

    // Don't show the banner if the user is in the teams demo
    // Teams Demo has it's own banner
    if (isInTeamsDemo) {
      return null;
    }

    const handleUpgradeClick = () => {
      /**
       * This is a temporary A/B test to see if users are more likely to convert
       * if they are taken directly to the checkout page
       * 9/6/2024 - 9/9/2024
       */
      const isDirectLinkToCheckout = Math.random() < 0.5;
      if (isDirectLinkToCheckout) {
        const targetUrl = `/upgrade/checkout`;
        trackSnowplowEvent({ category: 'CIPUpgradeBanner', action: 'click', label: 'upgradeButton', property: targetUrl });
        GaUtil.fireEvent('CIPUpgradeBanner', 'click', targetUrl);
        navigate(targetUrl);
      } else {
        const targetUrl = `${process.env.REACT_APP_V2_SITE_URL}/upgrade/`;
        trackSnowplowEvent({ category: 'CIPUpgradeBanner', action: 'click', label: 'upgradeButton', property: targetUrl });
        GaUtil.fireEvent('CIPUpgradeBanner', 'click', targetUrl);
        window.open(targetUrl, '_blank');
      }
    };

    const handleAddEmailClick = () => {
      const targetUrl = `/settings/account?settings=1`;
      trackSnowplowEvent({ category: 'CIPUpgradeBanner', action: 'click', label: 'addEmailButtonEduMilGov', property: targetUrl });
      navigate(targetUrl);
      Userpilot.track(USERPILOT_EVENTS.CIP_BANNER_CLICKED_ADD_EMAIL);
    };

    const handleEduMilGovUpgradeClick = () => {
      const targetUrl = `/upgrade/checkout`;
      trackSnowplowEvent({ category: 'CIPUpgradeBanner', action: 'click', label: 'upgradeButtonEduMilGov', property: targetUrl });
      navigate(targetUrl);
      Userpilot.track(USERPILOT_EVENTS.CIP_BANNER_CLICKED_UPGRADE);
    };

    /** New release banner */
    if (isNewReleasePromoEnabled) {
      return (
        <GradientBanner className={className}>
          <div className="grow px-6 text-2xl">
            <div>What’s new at Cybrary</div>
          </div>
          <AddLink className="flex p-3 px-6 mx-auto mt-2 text-black bg-slate-100 hover:bg-slate-200 rounded-md md:mt-0" to="https://www.cybrary.it/announcement" target="_blank">
            Check it out <Icon name="sparkler" className="ml-1 w-5 h-5 fill-current" />
          </AddLink>
        </GradientBanner>
      );
    }

    /** EDU MIL GOV Banner */
    if (isEduGovMilPromoEnabled) {
      return (
        <GradientBanner className={className}>
          {/** Don't show Add Email for user's who already qualify */}
          <If condition={!isEduGovMilUser}>
            <AddLink className="flex shrink-0 p-3 px-6 mx-auto mt-2 text-black bg-slate-100 hover:bg-slate-200 rounded-md md:mt-0" onClick={handleAddEmailClick}>
              Add Your Email
            </AddLink>
          </If>
          <div className="grow px-6 text-lg">
            <div>
              Empower your future with exclusive savings for student, military and government employees!
              <br />
              <span className="border-b-2">Save 50%</span> on your Cybrary Insider Pro subscription today!
            </div>
            <If condition={HELP_DESK_LINKS.EDU_MIL_GOV_UPGRADE}>
              <AddLink to={HELP_DESK_LINKS.EDU_MIL_GOV_UPGRADE} className="py-0 text-xs italic font-normal">
                Learn more about our .edu, .mil, and .gov discount program &gt;
              </AddLink>
            </If>
          </div>
          <AddLink onClick={handleEduMilGovUpgradeClick} className="flex p-3 px-6 mx-auto mt-2 text-black bg-slate-100 hover:bg-slate-200 rounded-md md:mt-0">
            Upgrade <Icon name="sparkler" className="ml-1 w-5 h-5 fill-current" />
          </AddLink>
        </GradientBanner>
      );
    }

    /** Default CIP Upgrade Banner - Based on Onboarding choices */
    return (
      <AddLink onClick={handleUpgradeClick} className="inline-block w-full">
        <GradientBanner className={twMerge(className, 'group')}>
          <div className="min-w-[175px] text-[24px] font-black">{content.title}</div>
          <div className="grow px-6 text-xs sm:text-lg">{content.body}</div>
          <div
            className="flex justify-center items-center py-2 px-6 mx-4 mt-2 
          max-w-[175px] text-black align-middle bg-slate-200 group-hover:bg-white rounded-sm transition-all duration-300 ease-in-out md:mt-0 md:mr-4"
          >
            Upgrade <Icon name="sparkler" className="ml-1 w-4 h-4" />
          </div>
        </GradientBanner>
      </AddLink>
    );
  })
);
export default CIPUpgradeBanner;
