import React, { useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import AddLink from '../AddLink/AddLink';
import { GradientBanner } from './CIPUpgradeBanner';

const BANNER_COPY = {
  '/enterprise/demo/organization/members': {
    title: 'Upgrade to start managing your team',
  },
  '/enterprise/demo/paths': {
    title: 'Upgrade to create custom learning paths',
  },
  '/enterprise/demo/goals': {
    title: 'Upgrade to set custom learning goals for your team',
  },
  '/enterprise/demo/reporting': {
    title: 'Upgrade to track your teams progress',
  },
  '/enterprise/demo/settings': {
    title: 'Upgrade to configure your team',
  },
  '/enterprise/demo/logs': {
    title: 'Upgrade to access audit logs for your team',
  },
  '/': {
    title: "Let's talk about your team's development",
    subtitle: 'Request a demo to learn more about managing your team',
  },
};

/** TeamsDemoBanner shown on the teams demo page at the top of the page
 * @param {string} className - additional class names to add to the component
 * @param {string} title - the title of the banner (default: 'Make this dashboard yours')
 * @param {string} subtitle - the subtitle of the banner (default: "Learn how you can start managing your team's development")
 */
function TeamsDemoBannerComponent({
  className,
  title = 'Make this dashboard yours',
  subtitle = "Learn how you can start managing your team's development",
  userStore,
  commonStore,
}) {
  const location = useLocation();

  const currentPath = location.pathname;
  const { bannerTitle, bannerSubtitle } = useMemo(() => {
    // Customize banner title based on page URL
    const copy = BANNER_COPY[currentPath];
    return {
      bannerTitle: copy?.title || title,
      bannerSubtitle: copy?.subtitle || subtitle,
    };
  }, [title, subtitle, currentPath]);

  useEffect(() => {
    if (userStore?.teamData?.id !== 'demo') {
      commonStore.removeCybRouterClass('upgrade-banner-adjustment');
    } else {
      commonStore.addCybRouterClass('upgrade-banner-adjustment');
    }
  }, [userStore?.teamData?.id]);

  // Only show the banner if the user is on the demo team
  // This is considered out "in demo" mode
  if (userStore?.teamData?.id !== 'demo') {
    return null;
  }

  return (
    <AddLink
      to="https://www.cybrary.it/demo-request"
      onClick={() => {
        trackSnowplowEvent({ category: 'TeamsDemoBanner', action: 'click', label: 'requestDemoButton' });
      }}
      target="_blank"
      rel="noreferrer"
    >
      <GradientBanner className={className}>
        <div className="w-full item-center">
          <div className="text-[24px] font-black">{bannerTitle}</div>
          <div className="grow px-6 text-lg">{bannerSubtitle}</div>
        </div>
        <div className="inline-block shrink-0 py-2.5 px-6 mt-2 text-sm font-bold leading-5 text-center text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm md:mt-0">
          Request Demo
        </div>
      </GradientBanner>
    </AddLink>
  );
}

const TeamsDemoBanner = inject('userStore', 'commonStore')(observer(TeamsDemoBannerComponent));

export default TeamsDemoBanner;
