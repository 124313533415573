import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import queryString from 'query-string';
import withRouter from '../../components/Router/withRouter';
import MemberDashboardHeading from '../../components/Enterprise/MemberDashboardHeading';
import DateRangeFilter from '../../components/Enterprise/DateRangeFilter';
import ExpandingContentTable from '../../components/Enterprise/ExpandingContentTable';
import ContentActivityTable from '../../components/Enterprise/ContentActivityTable';
import CheckAndDisplayCerts from '../../components/Enterprise/CheckAndDisplayCerts';
import DisplayCerts from '../../components/Dashboard/DisplayCerts';
import ProgressLabel from '../../components/Enterprise/ProgressLabel';
import WorkroleModal from '../../components/Analytics/WorkroleModal';
import ArchivedLabel from '../../components/ContentUpdates/ArchivedLabel';
import AddLink from '../../components/AddLink/AddLink';
import Table from '../../components/Table/Table';
import FormatUtil from '../../utils/formatUtil';
import BetaLabel from '../../components/Label/BetaLabel';
import StyledError from '../../components/Error/StyledError';
import Container from '../../components/Container/Container';
import Sticky from '../../components/Sticky/Sticky';
import Label from '../../components/Label/Label';
import './enterprise.css';
import '../../components/Analytics/analytics.css';
import OrganizationTrainingProgress from './OrganizationTrainingProgress';
import If from '../../components/If/If';
import OrganizationStatCards from './OrganizationStatCards';

const OrganizationMemberDashboard = inject(
  'enterpriseStore',
  'commonStore',
  'userStore',
  'authStore',
  'teamHierarchyStore'
)(
  observer(
    class OrganizationMemberDashboard extends React.Component {
      state = {
        orgId: null,
        userId: null,
        teamGroupId: null,
        hideMetrics: false,
        workroleModal: false,
        tabularView: false,
      };

      componentDidMount() {
        this.props.commonStore.setPageTitle('Member Profile | Cybrary');
        this.props.authStore.fireAttributionEvent();
        this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
      }

      componentWillUnmount() {
        this.props.enterpriseStore.setDefaultMemberDashboardData();
      }

      init = () => {
        const teamGroupId = this.props.userStore.team.reportsTeamGroupId;
        const newState = {
          ...this.state,
          orgId: this.props.match.params.orgId,
          teamGroupId,
          userId: this.props.match.params.id,
          hideMetrics: !!this.props.userStore.team && (!this.props.userStore.team.package_types || !this.props.userStore.team.package_types.length),
        };
        this.setState(newState, () => {
          this.props.enterpriseStore.getMemberDashboardHeading(this.props.match.params.orgId, this.props.match.params.id);
          this.props.enterpriseStore.getMemberDashboardCertificates(teamGroupId, this.props.match.params.id);
          this.props.enterpriseStore.getMemberDashboardGroupList(teamGroupId, this.props.match.params.id);
          this.props.teamHierarchyStore.getTeamHierarchy(this.props.userStore.team);
          this.getFilterableContent(true);
        });
      };

      getStatSections = (init, tabularViewChange) => {
        // Stats Cards
        const statsConfig = this.props.enterpriseStore.memberDashboardData.config.stats;
        statsConfig.stat_cards.forEach((item) => {
          this.props.enterpriseStore.getDashboardMetrics('memberDashboardData', this.state.teamGroupId, this.state.userId, item, null, this.state.tabularView, !!tabularViewChange);
        });
        statsConfig.mixed_stat.forEach((item) => {
          this.props.enterpriseStore
            .getDashboardMetrics('memberDashboardData', this.state.teamGroupId, this.state.userId, item, null, this.state.tabularView, !!tabularViewChange)
            .then(() => {
              const queryParams = queryString.parse(window.location.search);
              if ('goals' in queryParams && this.goals && init) {
                // Slight delay for rendering - Then scroll to section - Placed here so this is rendered before scrolling (pushes it out of place when rendered)
                setTimeout(() => {
                  this.goals.scrollIntoView({
                    behavior: 'smooth',
                  });
                }, 200);
              }
            });
        });
      };

      getFilterableContent = (init) => {
        this.props.enterpriseStore.resetMemberDashboardLearningQueryParams();
        this.props.enterpriseStore.getMemberDashboardAssignmentGoals(this.state.teamGroupId, this.state.userId, 'assignments');
        this.props.enterpriseStore.getMemberDashboardAssignmentGoals(this.state.teamGroupId, this.state.userId, 'goals');
        this.props.enterpriseStore.getMemberDashboardLearning(this.state.teamGroupId, this.state.userId);

        if (!this.state.hideMetrics) {
          this.getStatSections(init);
        }
      };

      setGraphView = (e, { checked }) => {
        const newState = {
          ...this.state,
          tabularView: checked,
        };
        this.setState(newState, () => {
          this.getStatSections(false, true);
        });
      };

      getMemberDashboardLearning = (queryParams, filter) => {
        const params = queryParams;
        params.status = filter;
        this.props.enterpriseStore.setDashboardQueryParams('memberDashboardData', 'learning', params);

        this.props.enterpriseStore.getMemberDashboardLearning(this.state.teamGroupId, this.state.userId);
      };

      getBreadCrumbs = (userData) => {
        const { userStore } = this.props;

        const preferredGroupId = userStore.team && userStore.team.preferredGroup ? userStore.team.preferredGroup : null;
        const label = userData.data ? userData.data.real_name || userData.data.name : 'Member Profile';
        const crumbs = [];
        if (this.props.userStore.team && this.props.userStore.team.permissions && this.props.userStore.team.permissions.canManageTeam) {
          crumbs.push({
            href: `/enterprise/${this.state.orgId}/organization/members`,
            label: 'Members',
          });
          crumbs.push({
            href: `/enterprise/${this.state.orgId}/organization/member/${this.state.userId}/dashboard`,
            label,
          });
        } else if (preferredGroupId) {
          crumbs.push({
            href: `/enterprise/${this.state.orgId}/organization/groups`,
            label: 'Groups',
          });
          crumbs.push({
            href: '',
            label,
          });
        }
        return crumbs;
      };

      applyDateFilter = (startDate, endDate, value) => {
        if (startDate) {
          this.props.enterpriseStore.setDashboardDateFilter('memberDashboardData', 'startDate', startDate);
        }
        if (endDate) {
          this.props.enterpriseStore.setDashboardDateFilter('memberDashboardData', 'endDate', endDate);
        }
        this.props.enterpriseStore.setDashboardDateFilter('memberDashboardData', 'range', value || null);

        this.getFilterableContent();
      };

      changeUserRole = (value) => {
        this.props.enterpriseStore
          .changeUserRoles(this.state.userId, value, this.state.teamGroupId)
          .then(() => {
            this.props.commonStore.triggerToast('success', {
              content: 'User role has been updated.',
            });
            this.props.enterpriseStore.getMemberDashboardHeading(this.state.orgId, this.props.match.params.id);
          })
          .catch((e) => {
            this.props.commonStore.triggerToast('error', {
              errorCode: e.response.status,
            });
          });
      };

      addUserToGroup = (value) => {
        this.props.enterpriseStore
          .addUsersToGroup(value, this.state.userId)
          .then(() => {
            this.props.commonStore.triggerToast('success', {
              content: 'User added to group',
            });
            // After adding user to new group, get new data set with updated info
            this.props.enterpriseStore.getMemberDashboardGroupList(this.state.teamGroupId, this.state.userId);
            this.props.enterpriseStore.getMemberDashboardHeading(this.state.orgId, this.state.userId);
          })
          .catch(() => {
            this.props.commonStore.triggerToast('error');
          });
      };

      removeUserFromTeam = () => {
        this.props.enterpriseStore
          .removeMembers(this.state.teamGroupId, this.state.userId)
          .then(() => {
            this.props.commonStore.triggerToast('success', {
              content: 'User has been removed from this team. Redirecting you back to your team user list',
            });
            setTimeout(() => this.props.navigate(`/enterprise/${this.state.orgId}/organization/members`), 3000);
          })
          .catch(() => {
            this.props.commonStore.triggerToast('error');
          });
      };

      changeLicense = (value) => {
        this.props.enterpriseStore
          .manageLicenses(this.state.teamGroupId, this.state.userId, value)
          .then(() => {
            this.props.authStore.fireAttributionEvent(!value ? 'license:revoked' : 'license:granted');
            this.props.commonStore.triggerToast('success', {
              content: "User's license status has been updated",
            });
            this.props.enterpriseStore.getMemberDashboardHeading(this.state.teamGroupId, this.state.userId);
          })
          .catch(() => {
            this.props.commonStore.triggerToast('error');
          });
      };

      getDateRangeFilter = (memberData) => {
        const { loading, error } = memberData;
        if (loading) {
          return null;
        }
        if (error) {
          return (
            <Container>
              <StyledError error={error} />
            </Container>
          );
        }
        return (
          <DateRangeFilter
            dateChange={this.applyDateFilter}
            dateRange={this.props.enterpriseStore.memberDashboardData.filters.range}
            allTimeStart={this.props.userStore.team.created_at}
            startDate={this.props.enterpriseStore.memberDashboardData.filters.startDate}
            endDate={this.props.enterpriseStore.memberDashboardData.filters.endDate}
            rangeSelectionClasses="w-full mb-6 md:mb-0 md:w-48 lg:w-52 md:mr-4"
          />
        );
      };

      getAssignmentsOrGoals = (section, filter) => {
        const { queryParams } = this.props.enterpriseStore.memberDashboardData[section];
        queryParams.status = filter;
        this.props.enterpriseStore.setDashboardQueryParams('memberDashboardData', section, queryParams);

        this.props.enterpriseStore.getMemberDashboardAssignmentGoals(this.state.teamGroupId, this.state.userId, section);
      };

      formatTitleWithType = (_, col, row, headings) => {
        const contentType = row[FormatUtil.getColIndex(headings, 'content_description_type')]?.value;
        const contentTypeId = row[FormatUtil.getColIndex(headings, 'content_description_type_id')]?.value || -1;
        const canViewReports = this.props.userStore.team && this.props.userStore.team.permissions && this.props.userStore.team.permissions.canViewReports;
        const courseId = row[FormatUtil.getColIndex(headings, 'content_description_id')].value;
        const courseName = row[FormatUtil.getColIndex(headings, 'content_description_title')].value;
        const userName = this.props.enterpriseStore.memberDashboardData.heading.data
          ? this.props.enterpriseStore.memberDashboardData.heading.data.real_name || this.props.enterpriseStore.memberDashboardData.heading.data.name
          : '';
        let href = '';

        if (canViewReports && Boolean(courseId) && Boolean(courseName)) {
          href = `/enterprise/${this.state.orgId}/reporting/report/learning-activities?userName=${userName}&userId=${
            this.state.userId
          }&contentDescriptionId=${courseId}&contentName=${encodeURIComponent(courseName)}`;
        }

        // Cybrary assessment type id = 46
        if (contentTypeId === 46) {
          const basePathname = window.location.pathname.split('/dashboard')[0];

          href = `${basePathname}/paths/assessments/match/${courseId}/summary?learnerId=${this.state.userId}&learnerName=${userName}`;
        }

        const colArr = col.value.split(' ');
        const lastWord = colArr[colArr.length - 1];
        colArr.pop();
        const archivedCol = row[FormatUtil.getColIndex(headings, 'content_description_archived_at')];
        const isArchived = archivedCol ? archivedCol.value : false;
        const tags = FormatUtil.getColIndex(headings, 'tags') > -1 ? row[FormatUtil.getColIndex(headings, 'tags')].value : null;

        return (
          <AddLink className="hover:font-semibold text-black hover:text-black underline hover:underline" to={href}>
            {colArr.join(' ')}{' '}
            <span className="whitespace-nowrap">
              {lastWord}
              <Label basic className="ml-2 text-xs rounded-sm">
                {contentType}
              </Label>
              {isArchived ? <ArchivedLabel className="ml-2" /> : null}
              {tags && tags.indexOf('Beta') !== -1 ? <BetaLabel className="ml-2" /> : null}
            </span>
          </AddLink>
        );
      };

      formatDate = (col) => {
        return col.value ? moment.utc(col.value).format('M/DD/YYYY') : '-';
      };

      getProgressLabel = (col, row, headings) => {
        return <ProgressLabel progressNum={col.value} completionDate={row[FormatUtil.getColIndex(headings, 'completed_at')].value} />;
      };

      checkCerts = (data, headings) => {
        if (!data || !headings) {
          return null;
        }
        const certData = {
          ...this.props.enterpriseStore.memberDashboardData.certificates,
        };
        const activityIdIdx = FormatUtil.getColIndex(headings, 'content_description_id');
        const activityId = !!data[activityIdIdx] && data[activityIdIdx].value ? data[activityIdIdx].value : null;
        const enrollmentIdIdx = FormatUtil.getColIndex(headings, 'id');
        const enrollmentId = !!data[enrollmentIdIdx] && data[enrollmentIdIdx].value ? data[enrollmentIdIdx].value : null;
        const completionDateIdx = FormatUtil.getColIndex(headings, 'completed_at');
        const completionDate = !!data[completionDateIdx] && data[completionDateIdx].value ? data[completionDateIdx].value : null;
        const typeIdx = FormatUtil.getColIndex(headings, 'content_description_type');
        const type = !!data[typeIdx] && data[typeIdx].value ? data[typeIdx].value : null;

        if (!activityId || !completionDate || !type) {
          return <Table.Cell key={activityId} />;
        }
        return (
          <Table.Cell key={activityId} className="text-center">
            <CheckAndDisplayCerts activityId={activityId} enrollmentId={enrollmentId} completionDate={completionDate} type={type} certData={certData.data} />
          </Table.Cell>
        );
      };

      getWorkroleData = (enrollmentId, scoreId) => {
        this.props.enterpriseStore.getWorkroleData(this.state.teamGroupId, 'memberDashboardData', enrollmentId, scoreId);
      };

      getWorkroleIcons = (row, headings, rowIdx, parentContentType) => {
        const { workroleData } = this.props.enterpriseStore.memberDashboardData;
        // Two different table types run through this method, both with an ID column, but different ID types (enrollment ID vs ScoreId)
        // If the parentContentType is Interview Mocha Test, we have a scoreID, NOT an enrollment ID
        const enrollmentIdIdx = ['iMocha Test', 'Interview Mocha Test'].indexOf(parentContentType) !== -1 ? -1 : FormatUtil.getColIndex(headings, 'id');
        const scoreIdIdx = ['iMocha Test', 'Interview Mocha Test'].indexOf(parentContentType) !== -1 ? FormatUtil.getColIndex(headings, 'id') : -1;
        const enrollmentId = enrollmentIdIdx > -1 ? row[enrollmentIdIdx].value : null;
        const scoreId = scoreIdIdx > -1 ? row[scoreIdIdx].value : null;
        const typeIdx = FormatUtil.getColIndex(headings, 'content_description_type');
        const type = typeIdx > -1 ? row[typeIdx].value : null;
        const completedAtIdx = FormatUtil.getColIndex(headings, 'completed_at');
        const completedAt = completedAtIdx > -1 ? row[completedAtIdx].value : null;
        const collections = ['Curriculum', 'Career Path', 'Collection'];
        const isParentCollection = collections.indexOf(parentContentType) > -1;
        if ((['iMocha Test', 'Interview Mocha Test'].indexOf(type) !== -1 && !!completedAt && !isParentCollection && !!enrollmentId) || !!scoreId) {
          return (
            <WorkroleModal
              key={`workrole-${rowIdx}`}
              enrollmentId={enrollmentId}
              scoreId={scoreId}
              loading={workroleData.loading}
              error={workroleData.error}
              data={workroleData.data}
              getWorkroleData={this.getWorkroleData}
              showLevelUpMsg={1 * this.state.userId === 1 * this.props.userStore.user.id}
            />
          );
        }
        return <Table.Cell key={`workrole-${rowIdx}`} />;
      };

      assignmentExpand = (content) => {
        return this.props.enterpriseStore.getMemberDashboardAssignmentGoalDetails(this.state.teamGroupId, content.enrollment_id, content.content_description_id, 'assignments');
      };

      goalExpand = (content) => {
        // If we have a content_description_id, pass it along to get goal children from API
        // Note: For Goals, only time you won't have content_description_id is when expanding a goal top level that has a course/activity assigned and not a path
        // Content ID isn't set at top level - Is manually set for path goals in transformer in store.
        // Goals come with activities/progress for direct children already, so we show those in this case
        if (content && content.content_description_id) {
          return this.props.enterpriseStore.getMemberDashboardAssignmentGoalDetails(this.state.teamGroupId, content.enrollment_id, content.content_description_id, 'goals');
        }

        return this.props.enterpriseStore.getDashboardGoalDetailsFromContent(content);
      };

      getAllScores = (contentDescriptionId) => {
        return this.props.enterpriseStore.getTeamMemberAllScores(this.state.orgId, this.state.userId, contentDescriptionId, { format: 'table' });
      };

      checkGoalExpansion = (goal) => {
        // If this goal has a content type, and it isn't 'none', return true. 'none' = learner decides (user picks content they want to take, nothing assigned)
        return goal.content_type && goal.content_type !== 'none';
      };

      render() {
        const storeData = this.props.enterpriseStore.memberDashboardData;
        const permissionsMap = this.props.teamHierarchyStore.trees[this.state.orgId] ? this.props.teamHierarchyStore.trees[this.state.orgId].permissionsMap : {};
        const filters = storeData?.filters ? { ...storeData.filters } : null;
        const orgId = this.state.teamGroupId;
        const memberId = this.state.userId;
        return (
          <div className="organization">
            <div className="organization-member-dashboard dashboard">
              <Container>
                <MemberDashboardHeading
                  orgId={this.state.orgId}
                  data={storeData.heading}
                  groups={storeData.groups}
                  groupList={storeData.groupList}
                  commonStore={this.props.commonStore}
                  changeUserRole={this.changeUserRole}
                  changeLicense={this.changeLicense}
                  addUserToGroup={this.addUserToGroup}
                  removeUserFromTeam={this.removeUserFromTeam}
                  canManageAdmins={this.props.userStore.team && this.props.userStore.team.permissions ? this.props.userStore.team.permissions.canManageAdmins : null}
                  canManageTeam={this.props.userStore.team && this.props.userStore.team.permissions ? this.props.userStore.team.permissions.canManageTeam : null}
                  breadcrumbs={this.getBreadCrumbs(storeData.heading)}
                  setGraphView={this.setGraphView}
                  graphView={this.state.tabularView}
                  groupPermissions={permissionsMap}
                />
              </Container>
              <div className="filterable-content">
                {!this.state.hideMetrics ? (
                  <Sticky stickyClasses="static lg:sticky lg:border-b lg:border-gray-400">
                    <Container>
                      <div className="py-9 md:flex md:justify-end md:items-center filter-container">
                        <p className="mr-2 mb-0">Filters:</p>
                        {this.getDateRangeFilter(storeData.heading)}
                      </div>
                    </Container>
                  </Sticky>
                ) : null}
                <Container>
                  <If condition={!!filters && !!memberId}>
                    <OrganizationStatCards filters={filters} orgId={orgId} memberId={memberId} />
                    <OrganizationTrainingProgress filters={filters} orgId={orgId} memberId={memberId} />
                  </If>
                  <div
                    style={{ scrollMarginTop: '6rem' }}
                    ref={(ref) => {
                      this.goals = ref;
                    }}
                  >
                    <ExpandingContentTable
                      heading="Goals"
                      headingTooltip
                      orgId={this.state.orgId}
                      userId={this.state.userId}
                      data={{ ...storeData.goals }}
                      hideFilters={false}
                      filterValue={this.props.enterpriseStore.memberDashboardData.goals.queryParams.status}
                      filterOptions={[
                        {
                          key: 'all',
                          text: 'All',
                          value: 'all',
                        },
                        {
                          key: 'in_progress',
                          text: 'In Progress',
                          value: 'in_progress',
                        },
                        {
                          key: 'expired',
                          text: 'Expired',
                          value: 'expired',
                        },
                        {
                          key: 'completed',
                          text: 'Completed',
                          value: 'completed',
                        },
                      ]}
                      filterPlaceholder="Status"
                      filterChange={(filter) => this.getAssignmentsOrGoals('goals', filter)}
                      expandFunction={this.goalExpand}
                      progressFormat={this.getProgressLabel}
                      titleTagFormat={this.formatTitleWithType}
                      learningHoursCalc={FormatUtil.convertSecondsToHours}
                      formatDate={this.formatDate}
                      checkCerts={this.checkCerts}
                      certData={storeData.certificates}
                      queryParams={queryString.parse(window.location.search)}
                      getWorkroleIcons={this.getWorkroleIcons}
                      getAllScores={this.getAllScores}
                      checkShouldExpand={this.checkGoalExpansion}
                      noResultsMsg="No Goals Found"
                    />
                  </div>
                  <ContentActivityTable
                    heading="Additional Learning"
                    headingTooltip
                    orgId={this.state.orgId}
                    userId={this.state.userId}
                    loading={storeData.learning.loading}
                    error={storeData.learning.error}
                    data={storeData.learning.data}
                    expandFunction={this.assignmentExpand}
                    filterParams={storeData.learning.queryParams}
                    progressFormat={this.getProgressLabel}
                    titleTagFormat={this.formatTitleWithType}
                    formatDate={this.formatDate}
                    learningHoursCalc={FormatUtil.convertSecondsToHours}
                    checkCerts={this.checkCerts}
                    noResultsMsg="There are no activities for this user during this time period"
                    certData={{ ...storeData.certificates }}
                    getActivityTableData={this.getMemberDashboardLearning}
                    linkToLearningActivityReport
                    filterValue={storeData.learning.queryParams.status}
                    hideFilters={false}
                    filterOptions={[
                      {
                        key: 'all',
                        text: 'All',
                        value: 'all',
                      },
                      {
                        key: 'completed',
                        text: 'Complete',
                        value: 'completed',
                      },
                      {
                        key: 'incomplete',
                        text: 'Incomplete',
                        value: 'incomplete',
                      },
                    ]}
                    filterPlaceholder="Status"
                    queryParams={queryString.parse(window.location.search)}
                    getWorkroleIcons={this.getWorkroleIcons}
                    getAllScores={this.getAllScores}
                  />
                  <DisplayCerts
                    certificates={storeData.certificates}
                    filterStartDate={this.props.enterpriseStore.memberDashboardData.filters.startDate}
                    filterEndDate={this.props.enterpriseStore.memberDashboardData.filters.endDate}
                  />
                </Container>
              </div>
            </div>
          </div>
        );
      }
    }
  )
);

export default withRouter(OrganizationMemberDashboard);
