import React, { useCallback } from 'react';
import { ONBOARDING_STEPS, useOnboarding } from '../../providers/OnboardingProvider';

import CIPFormStep from './OnboardingSteps/CIPFormStep';
import TeamFormStep from './OnboardingSteps/TeamFormStep';
import RecommendationsStep from './OnboardingSteps/RecommendationsStep';
import InitialStep from './OnboardingSteps/InitialStep';
import Container from '../Container/Container';

function OnboardingRouter() {
  const { step } = useOnboarding();
  const OnboardingStepJsx = useCallback(() => {
    // New onboarding steps
    switch (step) {
      case ONBOARDING_STEPS.CIP_FORM:
        return <CIPFormStep />;
      case ONBOARDING_STEPS.TEAM_FORM:
        return <TeamFormStep />;
      case ONBOARDING_STEPS.CONTENT_RECOMMENDATIONS:
        return <RecommendationsStep />;
      default:
        return <InitialStep />;
    }
  }, [step]);

  return (
    <Container size="dashboard" className="flex-col w-[100vw] h-[100vh]">
      <img
        alt=""
        width="224"
        height="58"
        className="m-auto my-2"
        src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg?w=250"
      />
      <OnboardingStepJsx />
    </Container>
  );
}

export default OnboardingRouter;
