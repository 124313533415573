/* eslint-disable no-unused-vars */
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import OnboardingStep from '../OnboardingStep';
import { ONBOARDING_OPTIONS, ONBOARDING_STEPS, useOnboarding } from '../../../providers/OnboardingProvider';

/** Step shown at the start of the onboarding flow */
function InitialStep() {
  const { setTeamOrCip, setStep } = useOnboarding();

  const individualsHighlights = [
    { hasAccess: true, description: 'Start or advance my career in cybersecurity' },
    { hasAccess: true, description: 'Prepare for certification exams' },
    { hasAccess: true, description: 'Learn new skills' },
    { hasAccess: true, description: 'Earn badges and CEUs/CPEs' },
    { hasAccess: true, description: 'Identify skill gaps via hands-on assessments' },
  ];

  const teamsHighlights = [
    { hasAccess: true, description: 'Monitor and track team performance' },
    { hasAccess: true, description: 'Baseline and remediate my team with custom learning recommendations' },
    { hasAccess: true, description: 'Upskill my team with role-aligned career paths' },
    { hasAccess: true, description: 'Prepare my team for certification exams' },
    { hasAccess: true, description: 'Stay informed about emerging threats' },
    { hasAccess: true, description: 'Create custom learning paths' },
    { hasAccess: true, description: 'Assign and manage training goals' },
  ];

  const handleClick = async (clickedOption) => {
    setTeamOrCip(clickedOption);
    const nextStep = clickedOption === ONBOARDING_OPTIONS.membershipFor.TEAM ? ONBOARDING_STEPS.TEAM_FORM : ONBOARDING_STEPS.CIP_FORM;
    setStep(nextStep);
  };

  return (
    <OnboardingStep title="Hey there, welcome to Cybrary!">
      <div className="flex flex-col justify-center items-center sm:flex-row sm:items-stretch sm:space-x-16">
        <Fade direction="left" triggerOnce duration={500}>
          <OnboardingStep.Button
            title="I'm here for my business"
            icon="user-group"
            onClick={() => handleClick(ONBOARDING_OPTIONS.membershipFor.TEAM)}
            className="flex-1 py-11 px-18 max-w-[380px] h-auto md:ml-0"
            packageHighlights={teamsHighlights}
          />
        </Fade>
        <Fade direction="right" triggerOnce duration={500}>
          <OnboardingStep.Button
            title="I'm here for myself"
            icon="user"
            onClick={() => handleClick(ONBOARDING_OPTIONS.membershipFor.CIP)}
            className="flex-1 py-11 px-18 max-w-[380px] h-auto md:ml-0"
            packageHighlights={individualsHighlights}
          />
        </Fade>
      </div>
    </OnboardingStep>
  );
}

export default InitialStep;
