import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import OnboardingStep from '../OnboardingStep';
import { ONBOARDING_OPTIONS, useOnboarding } from '../../../providers/OnboardingProvider';
import If from '../../If/If';
import Loading from '../../Loading/Loading';

function RecommendationsStep() {
  const { exitOnboarding, isSubmitting, isLoading, setCipGoal, cipGoal, savedFormFields, saveOnboardingData } = useOnboarding();
  const navigate = useNavigate();

  // Watch for cipGoal to be set, we can then save and navigate to the next step
  useEffect(() => {
    (async () => {
      if (cipGoal) {
        // Re-save the form fields with the new cipGoal field
        await saveOnboardingData(savedFormFields);
        // Exit the onboarding flow
        await exitOnboarding();
        // Navigate to the desired in-app page based on the cipGoal
        switch (cipGoal) {
          case ONBOARDING_OPTIONS.cipGoal.LAUNCH_MY_CAREER:
            navigate('/browse/paths/career-paths');
            break;
          case ONBOARDING_OPTIONS.cipGoal.GET_A_CERTIFICATION:
            navigate('/browse/paths/cert-prep-paths');
            break;
          case ONBOARDING_OPTIONS.cipGoal.UPSKILL_AND_PRACTICE:
            navigate('/browse/paths/skill-paths');
            break;
          default: // Default to home
            navigate('/');
            break;
        }
      }
    })();
  }, [cipGoal, exitOnboarding, navigate, savedFormFields, saveOnboardingData]);

  const loadingText = useMemo(() => (isSubmitting ? 'Saving your selections...' : 'Loading your content...'), [isSubmitting]);
  const titleText = useMemo(() => (isSubmitting || isLoading ? 'Setting up your account...' : 'What do you want to accomplish?'), [isSubmitting, isLoading]);
  return (
    <OnboardingStep title={titleText}>
      <If condition={isSubmitting || isLoading}>
        <Loading message={loadingText} wrapperClassName="w-full h-40" />
      </If>
      <If condition={!isSubmitting && !isLoading}>
        <div className="flex flex-col justify-center items-center sm:flex-row">
          <OnboardingStep.Button
            title="Start or Advance My Career"
            icon="rocket-2"
            onClick={() => setCipGoal(ONBOARDING_OPTIONS.cipGoal.LAUNCH_MY_CAREER)}
            className="justify-center w-64 h-64"
          />
          <OnboardingStep.Button
            title="Prepare for a Certification Exam"
            icon="ribbon-2"
            onClick={() => setCipGoal(ONBOARDING_OPTIONS.cipGoal.GET_A_CERTIFICATION)}
            className="justify-center w-64 h-64"
          />
          <OnboardingStep.Button
            title="Learn a New Skill"
            icon="growth"
            onClick={() => setCipGoal(ONBOARDING_OPTIONS.cipGoal.UPSKILL_AND_PRACTICE)}
            className="justify-center w-64 h-64"
          />
        </div>
      </If>
    </OnboardingStep>
  );
}

export default RecommendationsStep;
