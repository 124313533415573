import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import DropdownFilter from '../Dropdown/DropdownFilter';

const GroupSelect = inject('teamHierarchyStore')(
  observer(({ ariaLabel, teamHierarchyStore, team, selectedGroup, placeholder, groupFilter, defaultOption, handleGroupSelect, className = '', isCompact }) => {
    const [groupMap, setGroupMap] = useState({});
    const [teamHiearchy, setTeamHiearchy] = useState({});
    /* Simplify the hierarchy to a flat map of group id: group name without relationships
     * - for finding groups on selection and sending ID & name
     */
    const buildGroupMap = (data) => {
      let newGroupMap = {};
      if (!data || !data.length) {
        return newGroupMap;
      }

      data.forEach((group) => {
        newGroupMap[group.id] = group.name;
        if (group.children && group.children.length) {
          newGroupMap = {
            ...newGroupMap,
            ...buildGroupMap(group.children),
          };
        }
      });
      return newGroupMap;
    };

    /* Get the hierarchy of groups to nest subgroups within parent */
    const getHierarchy = async () => {
      const newTeamHiearchy = await teamHierarchyStore.getTeamHierarchy(team);
      teamHierarchyStore.setHierarchySelectOptions(team.id, groupFilter, null, defaultOption, false);
      setTeamHiearchy(newTeamHiearchy);
      const newGroupMap = buildGroupMap(teamHiearchy);
      setGroupMap(newGroupMap);
    };

    const handleChange = ({ value }) => {
      handleGroupSelect(value, groupMap[value]);
    };

    const isDemo = team?.id === 'demo';
    useEffect(() => {
      /** while in demo skip anything that sends backend requests */
      if (team && !isDemo) {
        getHierarchy();
      }
    }, [team.id]);

    return (
      <div className={`group-dropdown ${className}`}>
        <DropdownFilter
          name="group-dropdown"
          placeholder={placeholder || 'Select a Group...'}
          loading={teamHierarchyStore.isLoading(team.id)}
          ariaLabel={ariaLabel || 'Select group for filter'}
          options={teamHierarchyStore.getSelectOptions(team.id) ? teamHierarchyStore.getSelectOptions(team.id).slice() : null}
          value={selectedGroup}
          onChange={handleChange}
          isSearchable
          disabled={isDemo}
          isCompact={isCompact}
        />
      </div>
    );
  })
);

export default GroupSelect;
