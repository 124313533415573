import { observable, action, computed, makeObservable, runInAction } from 'mobx';
import moment from 'moment';
import queryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import Agents from '../agents/agents';
import GoalsUtil from '../utils/goalsUtil';
import SearchUtil from '../utils/searchUtil';
import FormatUtil from '../utils/formatUtil';
import { CONTENT_TYPES } from '../constants';
import DEMO_GOAL_STORE_TEAM_GOALS from '../pages/Enterprise/Demo/DEMO_GOAL_STORE_TEAM_GOALS.json';
import DEMO_EDIT_GOAL_DATA from '../pages/Enterprise/Demo/DEMO_EDIT_GOAL_DATA.json';

class GoalsStore {
  setQueryParams = (obj, key, value) => {
    this[obj].queryParams[key] = value;
  };

  teamGoals = {
    loading: true,
    error: false,
    data: null,
    queryParams: {
      sortCol: 'name',
      sortDirection: 'asc',
      ownerIds: [],
    },
    ownersList: [],
  };

  setDefaultTeamGoals = () => {
    this.teamGoals = {
      loading: true,
      error: false,
      data: null,
      queryParams: {
        sortCol: 'name',
        sortDirection: 'asc',
        ownerIds: [],
      },
      ownersList: [],
    };
  };

  createOwnerOptions = (data) => {
    if (!data || !data.length) {
      return [];
    }
    return data.map((item) => {
      return {
        value: item.id,
        text: item.name,
      };
    });
  };

  setGoalOwnersList = (obj, data, isTableTable) => {
    if (data) {
      const ownersList = [];
      if (isTableTable) {
        if (!!data.columns && !!data.tableData) {
          const ownersIdx = FormatUtil.getColIndex(data.columns, 'owners');
          // Loop through table rows and then loop through owners array to accumulate a list of owners
          data.tableData.forEach((row) => {
            const ownersCol = row[ownersIdx];
            if (ownersCol && ownersCol.value && ownersCol.value.length) {
              ownersList.push(...this.createOwnerOptions(ownersCol.value));
            }
          });
        }
      } else {
        data.forEach((goal) => {
          if (goal.owners && goal.owners.length) {
            ownersList.push(...this.createOwnerOptions(goal.owners));
          }
        });
      }
      // Remove duplicate owners before being put into filter multi-select dropdown
      this[obj].ownersList = FormatUtil.dedupArrayOfObjects(ownersList, 'value');
    }
    return [];
  };

  getSortQueryParams = () => {
    return {
      sortCol: this.teamGoals.queryParams.sortCol,
      sortDirection: this.teamGoals.queryParams.sortDirection,
    };
  };

  getTeamGoals = (teamId, query, resetSortParams = false) => {
    this.teamGoals.loading = true;
    this.teamGoals.error = false;
    if (resetSortParams) {
      this.teamGoals.queryParams.sortCol = 'name';
      this.teamGoals.queryParams.sortDirection = 'asc';
    }

    let goalsQuery = `${query && query.length ? `${query}&format=table` : '?format=table'}`;

    // if we have ownerIds, grab ownerIds query and append it to goals query...
    if (this.teamGoals.queryParams.ownerIds && this.teamGoals.queryParams.ownerIds.length) {
      goalsQuery += `${GoalsUtil.getOwnerIdsQuery(this.teamGoals.queryParams.ownerIds)}`;
    }
    goalsQuery += `&${queryString.stringify(this.getSortQueryParams())}`;

    return Agents.goals
      .getTeamGoals(teamId, goalsQuery)
      .then(
        action('fetchSuccess', (response) => {
          this.teamGoals.loading = false;
          this.teamGoals.data = response;
          this.teamGoals.goalsCount = response.tableData ? response.tableData.length : 0;
          return response;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.teamGoals.error = error.response || error;
          this.teamGoals.loading = false;
          return error;
        })
      );
  };

  /* Goal Creation */

  addEditGoalPrestine = true;

  resetAddEditGoalPrestine = () => {
    this.addEditGoalPrestine = true;
  };

  // For tracking if user has set goal name manually - To stop name suggestion
  userSetTitle = false;

  setGoalData = (goalType, key, val, suggestGoalName = true) => {
    this.goalData.data[key] = val;
    if (key === 'name') {
      this.userSetTitle = true;
    }
    if (!this.userSetTitle && ((goalType === 'user' && key === 'outcome_type') || key === 'outcome_settings' || key === 'participant_type' || key === 'participant_ids')) {
      // Small pause to ensure data has set before suggesting
      setTimeout(() => {
        if (suggestGoalName) {
          this.goalData.data.name = GoalsUtil.suggestGoalName(goalType, this.goalData.data);
        }
      }, 100);
    }
    this.addEditGoalPrestine = false;
  };

  findAndSetGoalData = (goalType, key, val, isPath) => {
    let dataSrc = null;
    if (key === 'participants') {
      dataSrc = 'goalParticipants';
    } else if (key === 'contents' && isPath) {
      dataSrc = 'paths';
    }
    if (dataSrc && this[dataSrc]) {
      if (this[dataSrc].loading) {
        // If it's still loading the source data, try again in a second
        setTimeout(() => this.findAndSetGoalData(goalType, key, val, isPath), 1000);
      } else {
        const srcData = this[dataSrc].data.filter((item) => item.value === val);
        if (srcData.length) {
          this.setGoalData(goalType, key, srcData);
        }
      }
    }
  };

  /* Team Goals Creation */

  goalData = {
    loading: true,
    error: false,
    data: {
      name: null,
      outcome_type: null,
      outcome_settings: {},
      owner_ids: [],
      participant_type: null,
      participant_ids: [],
      content_type: null,
      content_ids: [],
      due_type: null,
      due_date: null,
      start_date: null,
      due_interval: null,
      description: '',
      metric: 'percent_complete',
      certification_test_id: null,
      owners: [],
      contents: [],
      certification_test: null,
      participants: [],
      include_admins: false,
    },
  };

  setDefaultGoalData = () => {
    this.addEditGoalPrestine = true;
    this.userSetTitle = false;
    this.goalData = {
      loading: true,
      error: false,
      data: {
        name: null,
        outcome_type: null,
        outcome_settings: {},
        owner_ids: [],
        participant_type: null,
        participant_ids: [],
        include_admins: false,
        content_type: null,
        content_ids: [],
        due_type: null,
        due_date: null,
        start_date: null,
        due_interval: null,
        description: '',
        metric: 'percent_complete',
        certification_test_id: null,
        owners: [],
        contents: [],
        certification_test: null,
        participants: [],
      },
    };
  };

  getGoalData = (goalId, teamId) => {
    this.goalData.error = false;
    this.goalData.loading = true;
    const goalMethod = teamId ? Agents.goals.getTeamGoal : Agents.goals.getUserGoalDetails;
    return goalMethod(goalId, teamId)
      .then(
        action('fetchSuccess', (response) => {
          const data = { ...response };
          // If the outcome is learning_hours, the progress and totals come in seconds - convert to hours and store
          if (data.outcome_type === 'learning_hours') {
            data.outcome_settings.learning_hours_total = FormatUtil.convertSecondsToHours(data.outcome_settings.learning_seconds_total, true);
            data.progress.learning_hours_completed = FormatUtil.convertSecondsToHours(data.progress.learning_seconds_completed, true);
          }
          this.goalData.data = data;
          this.goalData.loading = false;
          if (teamId) {
            // Learning activities are retrieved on user query, so no options exist to set default values. Use contents returned to set those options
            this.learningActivities.data = this.transformAlgoliaHits(response.contents);
            this.learningActivities.loading = false;
            // If this goal has a practice test, also transform for options
            if (response.certification_test) {
              this.practiceTests.data = this.transformAlgoliaHits([response.certification_test]);
              this.practiceTests.loading = false;
            }
          }
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.goalData.error = error.response || error;
          this.goalData.loading = false;
        })
      );
  };

  saveGoal = (goalId, teamId) => {
    const data = { ...this.goalData.data };
    if (data.due_date) {
      data.due_date = moment(data.due_date).format('YYYY-MM-DD 23:59:59');
    }
    if (data.start_date) {
      // If the selected start date set to 00:00 is before the current time in UTC, send null (backend will set start date to help manage timezones)
      const formattedStartDate = moment(data.start_date).format('YYYY-MM-DD 00:00:00');
      data.start_date = moment(formattedStartDate).isBefore(moment.utc()) ? null : formattedStartDate;
    }
    // Make sure due and start date is in right format for backend
    this.addEditGoalPrestine = true;
    if (!goalId) {
      return Agents.goals[`${teamId ? 'postTeamGoal' : 'postUserGoal'}`](data, teamId);
    }
    return Agents.goals[`${teamId ? 'putTeamGoal' : 'putUserGoal'}`](goalId, data, teamId);
  };

  deleteGoal = (goalId, teamId) => {
    return Agents.goals[`${teamId ? 'deleteTeamGoal' : 'deleteUserGoal'}`](goalId, teamId);
  };

  goalParticipants = {
    loading: true,
    error: false,
    data: [],
  };

  constructor() {
    makeObservable(this, {
      setQueryParams: action,
      teamGoals: observable,
      setDefaultTeamGoals: action,
      setGoalOwnersList: action,
      getTeamGoals: action,
      addEditGoalPrestine: observable,
      resetAddEditGoalPrestine: action,
      userSetTitle: observable,
      setGoalData: action,
      goalData: observable,
      setDefaultGoalData: action,
      getGoalData: action,
      goalParticipants: observable,
      setDefaultGoalParticipants: action,
      getGoalParticipantsData: action,
      goalOwners: observable,
      setDefaultGoalOwners: action,
      getGoalOwnersData: action,
      paths: observable,
      setDefaultPaths: action,
      getPaths: action,
      learningActivities: observable,
      setDefaultLearningActivities: action,
      getLearningActivities: action,
      certifications: observable,
      setDefaultCertifications: action,
      getCertifications: action,
      practiceTests: observable,
      setDefaultPracticeTests: action,
      getPracticeTests: action,
      filterOptions: action,
      userGoalContentRecommendations: observable,
      setUserGoalContentRecError: action,
      getPresetContentData: action,
      getContentRecommendationsData: action,
      userGoals: observable,
      setDefaultUserGoals: action,
      getUserGoals: action,
      userGoalsList: observable,
      getUserGoalsList: action,
      trackingGoal: observable,
      hasTrackingGoal: computed,
      getTrackingGoalProgress: action,
      setCurrentTrackingGoal: action,
      lookForNewTrackingGoal: action,
      updateGoalTracking: action,
      enterDemo: action,
      exitDemo: action,
    });
  }

  setDefaultGoalParticipants() {
    this.goalParticipants = {
      loading: true,
      error: false,
      data: [],
    };
  }

  getGoalParticipantsData = (orgId, query) => {
    this.goalParticipants.loading = true;
    this.goalParticipants.error = false;
    return Agents.enterprise
      .getMembersList(orgId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.goalParticipants.data = response.data;
          this.goalParticipants.loading = false;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.goalParticipants.error = error.response || error;
          this.goalParticipants.loading = false;
        })
      );
  };

  goalOwners = {
    loading: true,
    error: false,
    data: [],
  };

  setDefaultGoalOwners() {
    this.goalOwners = {
      loading: true,
      error: false,
      data: [],
    };
  }

  getGoalOwnersData = (orgId, canManageTeam, hierarchyData) => {
    this.goalOwners.loading = true;
    this.goalOwners.error = false;
    // Get all of the group admins for the groups this user has permissions over
    const groupAdmins = this.getGroupAdminSelects(hierarchyData);
    // If can manage team, get team owners and admins to combine with group admins
    if (canManageTeam) {
      Agents.enterprise
        .getMembersList(orgId, '?roles[]=team-admin&roles[]=org-owner')
        .then(
          action('fetchSuccess', (response) => {
            // Combine the org owners/admins list with group admins list
            const adminsList = [...response.data, ...groupAdmins];
            this.goalOwners.data = GoalsUtil.dedupAdminList(adminsList);
            this.goalOwners.loading = false;
          })
        )
        .catch(
          action('fetchError', (error) => {
            Bugsnag.notify(error);
            this.goalOwners.error = error.response || error;
            this.goalOwners.loading = false;
          })
        );
    } else {
      // Can't manage team, so don't need org owners/admins. Just dedup group admin list
      this.goalOwners.data = GoalsUtil.dedupAdminList(groupAdmins);
      this.goalOwners.loading = false;
    }
  };

  getGroupAdminSelects = (data) => {
    const groupAdmins = [];
    if (!data || !data.length) {
      return groupAdmins;
    }
    // Determine which groups this user has permissions to manage so we can show the admins of those groups as owner options
    data.forEach((group) => {
      const childrenAdmins = this.getGroupAdminSelects(group.children);
      if (group.permissions.manage === 'edit') {
        const admins = group.admins.map((admin) => {
          return { text: admin.name, value: admin.id };
        });
        groupAdmins.push(...admins);
      }
      groupAdmins.push(...childrenAdmins);
    });
    return groupAdmins;
  };

  paths = {
    loading: true,
    error: false,
    data: [],
  };

  setDefaultPaths = () => {
    this.paths = {
      loading: true,
      error: false,
      data: [],
    };
  };

  mapProgramData = (data) => {
    return (
      data?.map((program) => {
        const metaLabelContentType = program.content_type?.nice_name?.replace('Program', 'Path');

        return {
          text: program.title,
          value: program.id,
          metaLabels: [metaLabelContentType, 'Cybrary'],
          tabGroup: 'Cybrary',
          type: program.content_type.nice_name,
          class: program.content_type.name,
          permalink: program.permalink,
          status: program.status,
        };
      }) || []
    );
  };

  getPaths = async (teamId, packageTypes) => {
    this.paths.loading = true;
    this.paths.error = false;
    const getCurriculaQuery = !packageTypes || packageTypes?.indexOf('avatao') === -1 ? '?excludeAvatao=1' : '';

    try {
      const careerProgramQuery = `?content_type_id=${CONTENT_TYPES.CAREER_PROGRAM}&recordsPerPage=100`;

      const skillProgramQuery = `?content_type_id=${CONTENT_TYPES.SKILL_PROGRAM}&recordsPerPage=100`;

      const certPrepProgramQuery = `?content_type_id=${CONTENT_TYPES.CERT_PREP_PROGRAM}&recordsPerPage=100`;

      const [curricula, careerPrograms, skillPrograms, certPrepPrograms] = await Promise.allSettled([
        teamId ? Agents.enterprise.getCurricula(teamId, getCurriculaQuery) : false,
        Agents.catalog.listContent(careerProgramQuery),
        Agents.catalog.listContent(skillProgramQuery),
        Agents.catalog.listContent(certPrepProgramQuery),
      ]);

      return runInAction(() => {
        let mappedCurricula = [];

        if (curricula?.value) {
          mappedCurricula =
            curricula?.value?.map((path) => ({
              text: path.name,
              value: path.id,
              metaLabels: [path.type, !path.team_id ? 'Cybrary' : 'Custom'],
              tabGroup: !path.team_id ? 'Cybrary' : 'Custom',
              type: path.type,
              status: path.status,
            })) || [];
        }

        this.paths.data = [
          ...mappedCurricula,
          ...this.mapProgramData(careerPrograms?.value?.data),
          ...this.mapProgramData(skillPrograms?.value?.data),
          ...this.mapProgramData(certPrepPrograms?.value?.data),
        ];

        this.paths.loading = false;
      });
    } catch (error) {
      return runInAction(() => {
        Bugsnag.notify(error);
        this.paths.error = error;
        this.paths.loading = false;
      });
    }
  };

  learningActivities = {
    loading: true,
    error: false,
    data: [],
  };

  setDefaultLearningActivities = () => {
    this.learningActivities = {
      loading: true,
      error: false,
      data: [],
    };
  };

  transformAlgoliaHits = (hits) => {
    if (!hits || !hits.length) {
      return [];
    }

    return hits.map((hit) => {
      return {
        text: hit.title,
        value: hit.id,
        metaLabels: [hit.type || hit.content_type.nice_name, FormatUtil.formatTime(hit.duration_seconds, 'hma').trim()],
        meta: {
          type: hit.type || hit.content_type.nice_name,
          duration: hit.duration_seconds,
          certification: hit.terms_info
            ? hit.terms_info.filter((term) => {
                return term.indexOf('certifications|') > -1;
              })
            : null,
        },
      };
    });
  };

  getLearningActivities = (query, teamPackages, contentType) => {
    this.learningActivities.loading = true;
    this.learningActivities.error = false;

    if (!query || !query.length) {
      return [];
    }

    let filters = SearchUtil.buildFilters('display_in_catalog', ['true']);

    if (contentType) {
      filters += ` AND ${SearchUtil.buildFilters('content_type.nice_name', [`"${contentType}"`])}`;
    } else {
      filters += ` AND NOT ${SearchUtil.buildFilters(
        'content_type.nice_name',
        ["'Career Path'", "'Assessment Path'", "'Collection'", "'Threat Actor Path'", "'Certification Prep'", "'Cert Prep'", "'Skill Path'", "'Career Program'"],
        'AND NOT'
      )}`;
    }

    const curriculumIndex = process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX;
    const options = {
      hitsPerPage: 30,
      filters,
    };

    return SearchUtil.fetchFromAlgolia(curriculumIndex, options, query, { teamPackages })
      .then((response) => {
        this.learningActivities.loading = false;
        this.learningActivities.data = this.transformAlgoliaHits(response.hits);
      })
      .catch((error) => {
        Bugsnag.notify(error);
        this.learningActivities.loading = false;
        this.learningActivities.error = error;
      });
  };

  certifications = {
    data: [],
    loading: true,
    error: false,
  };

  setDefaultCertifications = () => {
    this.practiceTests = {
      loading: true,
      error: false,
      data: [],
    };
  };

  getCertifications = () => {
    this.certifications.loading = true;
    this.certifications.error = false;

    return Agents.catalog
      .categoryTerms('?categories[]=Certifications&contentTypeIds[]=1')
      .then(
        action('fetchSuccess', (response) => {
          const certs = FormatUtil.sortArrayObjects([...response], 'group', 'name');
          certs.push({ name: 'Other', value: 'Other', group: 'Other' });
          // Transform response to select options
          const selectOpts = [];
          const certBodyMap = {};
          certs.forEach((cert) => {
            const certName = cert.name;
            selectOpts.push({
              text: certName,
              value: certName,
              metaLabels: [cert.group],
            });
            certBodyMap[certName] = cert.group;
          });
          this.certifications.loading = false;
          this.certifications.data = selectOpts;
          this.certifications.certBodyMap = certBodyMap;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.certifications.loading = false;
          this.certifications.error = error;
        })
      );
  };

  getCertificationBody = (certTitle) => {
    return this.certifications.certBodyMap[certTitle] || null;
  };

  practiceTests = {
    loading: true,
    error: false,
    data: [],
  };

  setDefaultPracticeTests = () => {
    this.practiceTests = {
      loading: true,
      error: false,
      data: [],
    };
  };

  getPracticeTests = (certification) => {
    this.practiceTests.loading = true;
    this.practiceTests.error = false;

    let filters = SearchUtil.buildFilters('display_in_catalog', ['true']);
    filters += ` AND (${SearchUtil.buildFilters('content_type.nice_name', ["'Kaplan Practice Test'", "'CyberVista Practice Test'"], 'OR')})`;
    const curriculumIndex = process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX;
    const options = {
      hitsPerPage: 250,
      filters,
    };

    return SearchUtil.fetchFromAlgolia(curriculumIndex, options)
      .then((response) => {
        this.practiceTests.loading = false;
        const practiceTests = this.transformAlgoliaHits(response.hits);
        // Get the cert body for the selected certification
        let certificationFormatted = certification;
        if (certificationFormatted) {
          certificationFormatted = certification.toLowerCase();
          certificationFormatted = certificationFormatted.split(' ').join('-');
        }
        // Set up tab groups. Check each practice test to see if it has a certification matching the cert body of the one user selected in outcome selection
        this.practiceTests.data = practiceTests.map((test) => {
          return {
            ...test,
            tabGroup: test.meta.certification.indexOf(`certifications|${certificationFormatted}`) > -1 ? 'Recommended' : null,
          };
        });
      })
      .catch((error) => {
        Bugsnag.notify(error);
        this.practiceTests.loading = false;
        this.practiceTests.error = error;
      });
  };

  filterOptions = (obj, query) => {
    if ((this[obj] && this[obj].data && this[obj].data.length) || this[obj]._raw) {
      if (!this[obj]._raw) {
        this[obj]._raw = [...this[obj].data];
      }
      this[obj].data = this[obj]._raw.filter((option) => {
        return !query || !query.length || option.text.toLowerCase().indexOf(query.toLowerCase()) > -1;
      });
    }
  };

  /* Learner Goal Creation */

  userGoalContentRecommendations = {
    loading: true,
    error: false,
    noCertCoursesFound: false,
    presetContent: false,
    data: [],
  };

  setDefaultUserGoalContentRecommendations = () => {
    this.userGoalContentRecommendations = {
      loading: true,
      error: false,
      noCertCoursesFound: false,
      presetContent: false,
      data: [],
    };
  };

  setUserGoalContentRecError = (error) => {
    Bugsnag.notify(error);
    this.userGoalContentRecommendations.loading = false;
    this.userGoalContentRecommendations.error = error;
  };

  getPresetContentData = (contentItem) => {
    const catalogIndex = process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX;
    const filters = `${SearchUtil.buildFilters('permalink', [contentItem])}`;
    const options = {
      filters,
    };
    return SearchUtil.fetchFromAlgolia(catalogIndex, options)
      .then(
        action('fetchSuccess', (response) => {
          if (response && response.hits && response.hits.length) {
            const preppedContent = GoalsUtil.TransformRecommendationsData(response.hits);
            this.userGoalContentRecommendations.presetContent = true;
            this.userGoalContentRecommendations.loading = false;
            this.userGoalContentRecommendations.data = preppedContent;
          }
        })
      )
      .catch(
        action('fetchError', (error) => {
          this.setUserGoalContentRecError(error);
        })
      );
  };

  // Get content info for recommendations from Algolia
  getContentRecommendationsData = (items) => {
    const catalogIndex = process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX;
    const releasedAtBuffer = 1 * moment().format('X') - 60 * 75;
    let filters = `${SearchUtil.buildFilters('display_in_catalog', ['true'])} AND released_at_timestamp < ${releasedAtBuffer}`;
    const contentPermalinks = items && items.length ? items.map((item) => item.permalink) : [];
    filters += ` AND ${SearchUtil.buildFilters('permalink', contentPermalinks)}`;
    const options = {
      filters,
    };
    return SearchUtil.fetchFromAlgolia(catalogIndex, options)
      .then(
        action('fetchSuccess', (response) => {
          if (response && response.hits && response.hits.length) {
            const preppedContent = GoalsUtil.TransformRecommendationsData(response.hits);
            this.userGoalContentRecommendations.loading = false;
            this.userGoalContentRecommendations.data = preppedContent;
          }
        })
      )
      .catch(
        action('fetchError', (error) => {
          this.setUserGoalContentRecError(error);
        })
      );
  };

  // Get recommendations from Algolia
  getContentRecommendations = (outcome, contentType) => {
    this.userGoalContentRecommendations.loading = true;
    this.userGoalContentRecommendations.error = false;
    const section = GoalsUtil.getContentSectionName(outcome, contentType);

    let filters = SearchUtil.buildFilters('pageSection', [section]);
    filters += ` AND ${SearchUtil.buildFilters('hidden', ['false'])}`;
    const dashboardIndex = process.env.REACT_APP_INSTANTSEARCH_DASHBOARD_INDEX;
    const options = {
      filters,
    };
    return SearchUtil.fetchFromAlgolia(dashboardIndex, options)
      .then(
        action('fetchSuccess', (response) => {
          if (response && response.hits && response.hits.length) {
            this.getContentRecommendationsData(response.hits);
          }
        })
      )
      .catch(
        action('fetchError', (error) => {
          this.setUserGoalContentRecError(error);
        })
      );
  };

  getCertificationContentRecommendations = (certification) => {
    this.userGoalContentRecommendations.loading = true;
    this.userGoalContentRecommendations.error = false;
    this.userGoalContentRecommendations.noCertCoursesFound = false;
    let filters = SearchUtil.buildFilters('display_in_catalog', ['true']);
    filters += ` AND (${SearchUtil.buildFilters('content_type.nice_name', ['Course'])})`;
    let certificationFormatted = certification;
    if (certificationFormatted) {
      certificationFormatted = certification.toLowerCase();
      certificationFormatted = certificationFormatted.split(' ').join('-');
    }
    filters += ` AND (${SearchUtil.buildFilters('terms_info', [`'certifications|${certificationFormatted}'`])})`;
    const catalogIndex = process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX;
    const options = {
      hitsPerPage: 100,
      filters,
    };

    return SearchUtil.fetchFromAlgolia(catalogIndex, options)
      .then(
        action('fetchSuccess', (response) => {
          if (response && response.hits && response.hits.length) {
            const preppedContent = GoalsUtil.TransformRecommendationsData(response.hits);
            this.userGoalContentRecommendations.loading = false;
            this.userGoalContentRecommendations.data = preppedContent;
          } else {
            // If we didn't find any courses with this certification, get some recommended content (hardcoded in Algolia)
            this.userGoalContentRecommendations.noCertCoursesFound = true;
            this.getContentRecommendations('certification', 'activity');
          }
        })
      )
      .catch(
        action('fetchError', (error) => {
          this.setUserGoalContentRecError(error);
        })
      );
  };

  /* User Goals - My Learning */
  userGoals = {
    loading: true,
    error: false,
    data: null,
    queryParams: {
      ownerIds: [],
    },
    ownersList: [],
  };

  setDefaultUserGoals = () => {
    this.userGoals = {
      loading: true,
      error: false,
      data: null,
      queryParams: {
        ownerIds: [],
      },
      ownersList: [],
    };
  };

  getUserGoals = (query) => {
    this.userGoals.loading = true;
    this.userGoals.error = false;

    let goalsQuery = query || '';
    if (this.userGoals?.queryParams?.ownerIds?.length) {
      goalsQuery += GoalsUtil.getOwnerIdsQuery(this.userGoals.queryParams.ownerIds);
    }

    return Agents.goals
      .getUserGoals(goalsQuery)
      .then(
        action('fetchSuccess', (response) => {
          this.userGoals.data = response;
          this.userGoals.loading = false;
          return response;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.userGoals.error = error;
          this.userGoals.loading = false;
          return error;
        })
      );
  };

  // Get the progress display value, which is what a progress percentage represents, based on metric provided
  getProgressDisplay = (progressAmounts, progressTotals, metric) => {
    if (!progressAmounts || !progressTotals || !metric) {
      return 0;
    }

    if (metric === 'certification') {
      return Math.floor(progressAmounts.passed) || 0;
    }

    const progress = 1 * progressAmounts[`${metric}_completed`];
    const progressTotal = 1 * progressTotals[`${metric}_total`];
    // If we don't have a progress value or total value, just return 0
    if (Number.isNaN(Number(progress)) || Number.isNaN(Number(progressTotal))) {
      return 0;
    }

    return Math.floor(progress);
  };

  // Get the percentage value of progress for use in the progress bars, based on the metric provided
  getProgressPercentage = (progressAmounts, progressTotals, metric) => {
    if (!progressAmounts || !progressTotals || !metric) {
      return 0;
    }

    // If the metric is learning_hours, calculate the progress percentage based on seconds, which isn't rounded down
    // For consistency in list views where backend is doing calc on seconds
    let progressMetric = metric;
    if (metric === 'learning_hours') {
      progressMetric = 'learning_seconds';
    }

    let progress = null;
    let progressTotal = null;
    if (progressMetric === 'certification') {
      progress = progressAmounts.passed;
      progressTotal = progressAmounts.participant_count;
    } else {
      progress = progressAmounts[`${progressMetric}_completed`];
      progressTotal = progressTotals[`${progressMetric}_total`];
    }

    if (!progress || !progressTotal) {
      return 0;
    }
    return Math.floor((progress / progressTotal) * 100);
  };

  // 'Fix' for when a goal is completed, and the content inside the goal is increased (changed path) - Still show the total as the number when completed
  prepGoalTotals = (progress, totals, outcomeType) => {
    const preppedTotals = { ...totals };
    const nonActivityOutcomes = ['learning_hours', 'ceus', 'certification'];
    const isActivityOutcome = outcomeType && nonActivityOutcomes.indexOf(outcomeType) === -1;
    const isComplete = progress && 'activities_completed' in progress && progress.percent_completed && 1 * progress.percent_completed === 100;
    const hasActivityTotals = totals && 'activities_total' in totals;
    if (isActivityOutcome && isComplete && hasActivityTotals) {
      preppedTotals.activities_total = 1 * progress.activities_completed;
    }
    return preppedTotals;
  };

  // Get the suffix (metric display) display for the progress along with the total (goal amount) if provided
  getProgressSuffix = (progressValue, progressTotals, metric, participantCount, showTotalValue) => {
    const suffixMap = {
      ceus: 'CEU',
      learning_hours: 'Hour',
      certification: 'passed',
    };
    let progressTotal = progressTotals ? progressTotals[`${metric}_total`] : null;
    if (metric === 'certification') {
      progressTotal = participantCount;
    }

    if (metric === 'activities') {
      // Learning activity doesn't show any unit labels, just the activity counts
      return showTotalValue ? ` / ${progressTotal}` : '';
    }

    let suffix = suffixMap[metric];
    if (!suffix) {
      return null;
    }
    suffix += progressValue !== 1 && metric !== 'certification' ? 's' : '';
    return progressTotal && showTotalValue ? ` / ${progressTotal} ${suffix}` : ` ${suffix}`;
  };

  /* Goal Achievement Tracking */

  trackingGoalPolling = null;

  // Tracks when it's done looking for/setting a tracking goal, to prevent conflicts if another component is looking for a tracking goal on app load
  trackingGoalInit = false;

  pollingInterval = 300 * 1000; // every 5 mins

  startTrackingGoalPolling = () => {
    if (!this.trackingGoalPolling) {
      this.trackingGoalPolling = setInterval(() => {
        // If we don't get any trackingGoal set, just clear the interval and stop polling
        if (!this.hasTrackingGoal) {
          this.stopTrackingGoalPolling();
        }
        this.getTrackingGoalProgress();
      }, this.pollingInterval);
    }
  };

  stopTrackingGoalPolling = () => {
    if (this.trackingGoalPolling) {
      clearInterval(this.trackingGoalPolling);
      this.trackingGoalPolling = null;
    }
  };

  /* Used in goal tracker in top nav */
  userGoalsList = {
    loading: true,
    error: false,
    data: null,
    activeGoals: null,
  };

  filterGoalDataByStatus = (data, status) => {
    if (!data) {
      return [];
    }
    return data.filter((goal) => goal.status === status);
  };

  getUserGoalsList = () => {
    this.userGoalsList.loading = true;
    this.userGoalsList.error = false;
    const query = `?abridged=1`;
    return Agents.goals
      .getUserGoals(query)
      .then((response) => {
        if (response && response.length) {
          const activeGoals = this.filterGoalDataByStatus(response, 'in_progress');
          // Find the tracked goal, if any, and move it to top
          activeGoals.forEach((goal, idx) => {
            if (goal.tracked) {
              activeGoals.splice(idx, 1);
              activeGoals.unshift(goal);
            }
          });
          this.userGoalsList.activeGoals = activeGoals;
          // Sort goals by most recently worked
          const sortedGoals = FormatUtil.sortArrayObjects([...response], 'progress_updated_at').reverse();
          this.userGoalsList.data = sortedGoals;
        }
        this.userGoalsList.loading = false;
      })
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.userGoalsList.error = error.response || error;
          this.userGoalsList.loading = false;
        })
      );
  };

  trackingGoal = null;

  get hasTrackingGoal() {
    return !!this.trackingGoal;
  }

  getTrackingGoalProgress = () => {
    if (!this.trackingGoal || !this.trackingGoal.id) {
      return null;
    }
    return Agents.goals
      .getUserGoalDetails(this.trackingGoal.id, '?abridged=true')
      .then((response) => {
        if (response) {
          const trackedGoalData = { ...response };
          this.trackingGoal = trackedGoalData;
        }
      })
      .catch(
        action('fetchError', (error) => {
          // If error due to 404, the goal is gone! Find a new one
          if (error && error.response && error.response.status && error.response.status === 404) {
            this.lookForNewTrackingGoal();
          }
          Bugsnag.notify(error);
        })
      );
  };

  setCurrentTrackingGoal = () => {
    if (this.userGoalsList.data && this.userGoalsList.data.length) {
      const [trackedGoal] = this.userGoalsList.data.filter((goal) => goal.tracked);
      if (trackedGoal) {
        this.trackingGoal = trackedGoal;
        this.trackingGoalInit = true;
      } else {
        // We don't have a goal set to be tracked. Let's find the best one
        this.lookForNewTrackingGoal();
      }
    } else {
      this.trackingGoalInit = true;
    }
  };

  lookForNewTrackingGoal = (data) => {
    const prevTrackedGoal = this.trackingGoal;
    this.trackingGoal = null;
    const goalData = data || this.userGoalsList.activeGoals;
    // Filter out the current goal already being tracked, if one exists
    const filteredGoals = goalData.filter((goal) => !goal.tracked);
    if (filteredGoals && filteredGoals.length) {
      const sortedGoals = FormatUtil.sortArrayObjects([...filteredGoals], 'progress_updated_at').reverse();
      this.updateGoalTracking(sortedGoals[0]);
    } else {
      // If we didn't find a new goal to track, make sure we are no longer tracking the old one (normally it'll just shift tracking to the new one, so this isn't needed)
      if (prevTrackedGoal) {
        this.updateGoalTracking(prevTrackedGoal, true);
      }
      this.trackingGoalInit = true;
    }
  };

  updateGoalTracking = (goal, untrack) => {
    if (!goal || (this.trackingGoal && goal.id === this.trackingGoal.id)) {
      return null;
    }
    const reqBody = untrack ? { untrack: true } : {};
    return Agents.goals
      .setGoalTracking(goal.id, reqBody)
      .then(() => {
        this.getUserGoalsList().then(() => {
          if (!untrack) {
            this.setCurrentTrackingGoal();
          }
        });
      })
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  };

  enterDemo = () => {
    this.teamGoals = DEMO_GOAL_STORE_TEAM_GOALS;
    this.goalData = {
      loading: false,
      error: false,
      data: DEMO_EDIT_GOAL_DATA,
    };
  };

  exitDemo = () => {
    // reset goals to default data
    this.setDefaultTeamGoals();
    this.setDefaultGoalData();
  };
}

export default new GoalsStore();
