import React from 'react';
import { useNavigate } from 'react-router-dom';
import OnboardingStep from '../OnboardingStep';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { useOnboarding } from '../../../providers/OnboardingProvider';
import { COUNTRIES, SHARED_DEPARTMENTS, SHARED_ROLES, TEAM_LEADS } from '../../../constants';
import OnboardingFormContainer from '../OnboardingFormContainer';

function TeamFormStep() {
  const { setSavedFormFields, teamOrCip, saveOnboardingData, exitOnboarding, isSubmitting, isLoading, user, profileData } = useOnboarding();
  const navigate = useNavigate();
  const handleStep = async (formFields) => {
    setSavedFormFields(formFields);
    await saveOnboardingData(formFields);

    // Exit the onboarding flow
    await exitOnboarding();

    navigate('/enterprise/demo');
  };
  const TEAM_ONBOARDING_FORM_FIELDS = {
    order: [['firstName', 'lastName'], ['title', 'teamLead'], 'department', 'company', 'business_email', 'phone', 'country', 'membershipFor', 'goal', 'submit'],
    fields: {
      firstName: {
        id: 'onboarding-team-first-name',
        type: 'text',
        label: 'First Name',
        validations: ['required'],
        defaultValue: user?.first_name,
      },
      lastName: {
        id: 'onboarding-team-last-name',
        type: 'text',
        label: 'Last Name',
        validations: ['required'],
        defaultValue: user?.last_name,
      },
      title: {
        id: 'onboarding-team-title',
        type: 'select',
        label: 'Title',
        placeholder: 'Select a title',
        options: SHARED_ROLES,
        validations: ['required'],
        defaultValue: profileData?.title,
      },
      teamLead: {
        id: 'onboarding-team-team-lead',
        type: 'select',
        label: 'Do you lead a team?',
        placeholder: 'Select an option',
        options: TEAM_LEADS,
        validations: ['required'],
        defaultValue: profileData?.teamLead,
      },
      department: {
        id: 'onboarding-team-department',
        type: 'select',
        placeholder: 'Select Department',
        label: 'Department',
        quasiRequired: true,
        options: SHARED_DEPARTMENTS,
        defaultValue: profileData?.department,
      },
      company: {
        id: 'onboarding-team-company',
        type: 'text',
        label: 'Company',
        validations: ['required'],
        defaultValue: profileData?.company,
      },
      business_email: {
        id: 'onboarding-business-email',
        type: 'email',
        label: 'Business Email',
        placeholder: 'name@example.com',
        validations: ['required'],
        description: 'Please enter your business email address. This form does not accept addresses from public email providers.',
        defaultValue: profileData?.business_email,
      },
      phone: {
        id: 'onboarding-team-phone',
        type: 'phone',
        label: 'Phone Number',
        validations: ['required'],
        defaultValue: user?.phone,
      },
      country: {
        id: 'onboarding-team-country',
        type: 'select',
        label: 'Country',
        placeholder: 'Select a country',
        defaultValue: user?.country || 'US',
        options: COUNTRIES,
        validations: ['required'],
      },
      membershipFor: {
        id: 'onboarding-team-membership-for',
        type: 'text',
        defaultValue: teamOrCip,
        className: 'hidden',
      },
      goal: {
        id: 'onboarding-team-goal',
        type: 'text',
        defaultValue: '',
        className: 'hidden',
      },
      submit: {
        type: 'button',
        color: 'pink',
        className: 'mx-auto',
        label: 'Submit',
        loading: isSubmitting || isLoading,
      },
    },
  };
  return (
    <OnboardingStep title="Create Your Profile">
      <OnboardingFormContainer>
        <DynamicForm form={TEAM_ONBOARDING_FORM_FIELDS} onSubmit={handleStep} />
      </OnboardingFormContainer>
    </OnboardingStep>
  );
}
export default TeamFormStep;
